// Animal Ref
// 1,Rat,_子
// 2,Ox,_丑
// 3,Tiger,_寅
// 4,Rabbit,_卯
// 5,Dragon,_辰
// 6,Snake,_巳
// 7,Horse,_午
// 8,Sheep,_未
// 9,Monkey,_申
// 10,Rooster,_酉
// 11,Dog,_戌
// 12,Boar,_亥

// Breaker Day
export const breakerParse = {
  1: 7,
  2: 8,
  3: 9,
  4: 10,
  5: 11,
  6: 12,
  7: 1,
  8: 2,
  9: 3,
  10: 4,
  11: 5,
  12: 6,
};

// 6 Yearly/MonthlyThree killings
export const robberyShaParse = {
  1: 6,
  2: 3,
  3: 12,
  4: 9,
  5: 6,
  6: 3,
  7: 12,
  8: 9,
  9: 6,
  10: 3,
  11: 12,
  12: 9,
};

export const calamityShaParse = {
  1: 7,
  2: 4,
  3: 1,
  4: 10,
  5: 7,
  6: 4,
  7: 1,
  8: 10,
  9: 7,
  10: 4,
  11: 1,
  12: 10,
};

export const annualShaParse = {
  1: 8,
  2: 5,
  3: 2,
  4: 11,
  5: 8,
  6: 5,
  7: 2,
  8: 11,
  9: 8,
  10: 5,
  11: 2,
  12: 11,
};

// 7 No wealth days
export const noWealthDayParse = {
  0: [],
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [],
  11: [],
  12: [],
  13: [],
  14: [],
  15: [],
  16: [],
  17: [],
  18: [],
  19: [],
  20: [],
  21: [],
  22: [],
  23: [],
  24: [],
  25: [],
  26: [],
  27: [],
  28: [],
  29: [],
  30: [],
  31: [],
  32: [],
  33: [],
  34: [],
  35: [],
  36: [],
  37: [],
  38: [],
  39: [33, 35],
  40: [33, 35],
  41: [41, 42],
  42: [41, 42],
  43: [41, 42],
  44: [41, 42],
  45: [41, 42],
  46: [41, 42],
  47: [41, 42],
  48: [41, 42],
  49: [41, 42],
  50: [41, 42],
  51: [41, 42],
  52: [41, 42],
  53: [41, 42],
  54: [41, 42],
  55: [41, 42],
  56: [41, 42],
  57: [41, 42],
  58: [41, 42],
  59: [41, 42],
  60: [41, 42],
};
// 8 Personal Wealth Sky Horse
export const personalWealthParse = {
  1: 3,
  2: 12,
  3: 9,
  4: 6,
  5: 3,
  6: 12,
  7: 9,
  8: 6,
  9: 3,
  10: 12,
  11: 9,
  12: 6,
};
// No wealth days

// 11 The personal breaker day
export const personalBreakerParse = {
  1: 7,
  2: 8,
  3: 9,
  4: 10,
  5: 11,
  6: 12,
  7: 1,
  8: 2,
  9: 3,
  10: 4,
  11: 5,
  12: 6,
};
// 12 combine animal
export const combineAnimalParse = {
  1: 2,
  2: 1,
  3: 12,
  4: 11,
  5: 10,
  6: 9,
  7: 8,
  8: 7,
  9: 6,
  10: 5,
  11: 4,
  12: 3,
};

// 14 Solitary Star
export const solitaryStarGroomParse = {
  1: 3,
  2: 3,
  3: 6,
  4: 6,
  5: 6,
  6: 9,
  7: 9,
  8: 9,
  9: 12,
  10: 12,
  11: 12,
  12: 3,
};

// 14 Solitary Star
export const solitaryStarBrideParse = {
  1: 11,
  2: 11,
  3: 2,
  4: 2,
  5: 2,
  6: 5,
  7: 5,
  8: 5,
  9: 8,
  10: 8,
  11: 8,
  12: 11,
};

// 15 Sky Happiness Star
export const skyHappinessStarParse = {
  1: 9,
  2: 10,
  3: 11,
  4: 12,
  5: 1,
  6: 2,
  7: 3,
  8: 4,
  9: 5,
  10: 6,
  11: 7,
  12: 8,
};
// 16 Year Prosperity Star
export const yearProsperityParse = {
  甲: 3,
  乙: 4,
  丙: 6,
  丁: 7,
  戊: 6,
  己: 7,
  庚: 9,
  辛: 10,
  壬: 12,
  癸: 1,
};
// Asset acquisition
export const assetAcquireParse = {
  甲: [8],
  丙: [11],
  戊: [5, 11, 2, 8],
  庚: [2],
  壬: [5],
  乙: [5, 11, 2, 8],
  丁: [11],
  己: [8],
  辛: [2],
  癸: [5],
};
// 18 Heavenly Wealth Star
export const heavenlyWealthStarParse = {
  甲: '戊',
  乙: '己',
  丙: '庚',
  丁: '辛',
  戊: '壬',
  己: '癸',
  庚: '甲',
  辛: '乙',
  壬: '丙',
  癸: '丁',
};
// 19 Personal Extinction Star
export const personalExtinctionStarParse = {
  甲: 9,
  乙: 10,
  丙: 12,
  丁: 1,
  戊: 12,
  己: 1,
  庚: 3,
  辛: 4,
  壬: 6,
  癸: 7,
};
// 20 Illness Star 病符星
export const illnessStarParse = {
  1: 12,
  2: 1,
  3: 2,
  4: 3,
  5: 4,
  6: 5,
  7: 6,
  8: 7,
  9: 8,
  10: 9,
  11: 10,
  12: 11,
};
// 21 Heavenly Doctor Star
export const heavenlyDoctorStarParse = {
  1: 12,
  2: 1,
  3: 2,
  4: 3,
  5: 4,
  6: 5,
  7: 6,
  8: 7,
  9: 8,
  10: 9,
  11: 10,
  12: 11,
};
// 22 Starting diet
export const startingDietParse = {
  1: 7,
  2: 8,
  3: 9,
  4: 10,
  5: 11,
  6: 12,
  7: 1,
  8: 2,
  9: 3,
  10: 4,
  11: 5,
  12: 6,
};
// 23 Personal Nobleman
export const personalNobleManParse = {
  甲: [2, 8],
  乙: [1, 9],
  丙: [12, 10],
  壬: [4, 6],
  辛: [7, 3],
  戊: [2, 8],
  己: [1, 9],
  丁: [12, 10],
  癸: [4, 6],
  庚: [2, 8],
};
// 25 heavenlyVirtueParse
export const heavenlyVirtueParse = {
  1: '丁',
  2: 9,
  3: '壬',
  4: '辛',
  5: 12,
  6: '甲',
  7: '癸',
  8: 3,
  9: '丙',
  10: '乙',
  11: 6,
  12: '庚',
};
// 26 monhlyVirtue
export const monthlyVirtueParse = {
  1: '壬',
  2: '庚',
  3: '丙',
  4: '甲',
  5: '壬',
  6: '庚',
  7: '丙',
  8: '甲',
  9: '壬',
  10: '庚',
  11: '丙',
  12: '甲',
};
// 27 threeHarmony
export const threeHarmonyPartialParse = {
  1: [],
  2: [6, 10],
  3: [],
  4: [],
  5: [1, 9],
  6: [],
  7: [],
  8: [12, 4],
  9: [],
  10: [],
  11: [3, 7],
  12: [],
};

export const threeHarmonySet = {
  1: 3,
  2: 4,
  3: 1,
  4: 2,
  5: 3,
  6: 4,
  7: 1,
  8: 2,
  9: 3,
  10: 4,
  11: 1,
  12: 2,
};
export const threeHarmonySetGroup = {
  0: [0, 0, 0],
  1: [3, 7, 11],
  2: [4, 8, 12],
  3: [1, 5, 9],
  4: [2, 6, 10],
};
export const threeHarmonyDaySet = {
  1: 0,
  2: 4,
  3: 0,
  4: 0,
  5: 3,
  6: 0,
  7: 0,
  8: 2,
  9: 0,
  10: 0,
  11: 1,
  12: 0,
};
