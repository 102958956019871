<template>
    <nav>
        <v-app-bar app color="white" outlined elevation="1">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>{{ navTitle }}</v-toolbar-title>

            <v-spacer></v-spacer>
            <!-- Calendar -->
            <div v-if="navTitle == 'ปฏิทิน'">
                <v-row>
                    <v-dialog transition="dialog-top-transition" max-width="700">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mx-4" elevation="2" v-bind="attrs" v-on="on">Publish ปฏิทิน</v-btn>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar color="indigo darken-2" dark>Update Version ของปฏิทิน</v-toolbar>
                                <v-textarea class="pa-4" placeholder="เขียนอะไรบางอย่าง" v-model="changelogText"
                                    prepend-icon="mdi-pipe-wrench" filled hide-details>
                                </v-textarea>
                                <v-card-actions class="justify-end">
                                    <v-btn text @click="
                                        publishCalendar();
                                    dialog.value = false;
                                    ">ตกลง</v-btn>
                                    <v-btn text @click="dialog.value = false">ยกเลิก</v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>

                    <v-btn class="mx-4" elevation="2" @click="onPickLottery">อัพโหลด Lottery</v-btn>
                    <input type="file" @change="onSelectedLottery" style="display: none" ref="fileLottery" multiple
                        accept=".png" />

                    <v-btn class="mx-4" elevation="2" to="/calendar-history">ประวัติ</v-btn>

                    <v-btn class="mx-4" elevation="2" @click="onPickFile">นำเข้าไฟล์ .csv</v-btn>

                    <input type="file" @change="onSelectedFile" style="display: none" ref="fileInput" accept=".csv" />
                </v-row>
            </div>
            <div v-if="navTitle == 'บทความ'">
                <v-btn class="mx-4" elevation="2" to="/content/add">เพิ่มบทความ</v-btn>
            </div>

            <!-- Users -->
            <div v-else-if="navTitle === 'สมาชิก'">
                <v-btn class="mx-4" elevation="2" to="/users-dashboard">User Dashboard</v-btn>
            </div>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" app>
            <!-- NumEiang Icon & Title -->
            <v-list-item class="py-2">
                <v-avatar size="50" color="deep-orange darken-4">
                    <img src="../assets/numeiang_logo.png" />
                </v-avatar>
                <v-list-item-content class="px-5" style="font-family: Arial">
                    <v-list-item-title class=""> น่ำเอี๊ยง </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list nav>
                <div v-for="item in navigationPath" :key="item.title">
                    <v-list-group v-if="item.children" :value="true" no-action router :to="item.path" mandatory
                        color="red darken-4" :append-icon="item.children ? '$expand' : null" :group="item.path">
                        <template v-slot:activator>
                            <v-list-item-icon class="custom-v-list-item-icon">
                                <v-icon v-if="item.icon">{{
                                    item.icon
                                    }}</v-icon>
                                <custom-svg v-if="item.svg" :name="item.svg"></custom-svg>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    item.title
                                    }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item v-for="child in item.children" :key="child.title" router :to="child.path"
                            class="children-v-list-item">
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    child.title
                                    }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <!-- normal path -->
                    <v-list-item v-else :class="item.class" router :to="item.path" class="custom-v-list-group__header"
                        color="red darken-4">
                        <v-list-item-icon class="custom-v-list-item-icon">
                            <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                            <custom-svg v-if="item.svg" :name="item.svg"></custom-svg>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{
                                item.title
                                }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
                <v-divider class="nav-divider"></v-divider>
                <v-list-item @click="onLogout">
                    <!-- logout -->
                    <v-list-item-icon>
                        <v-icon>{{ logout.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            logout.title
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </nav>
</template>

<script>
import CustomSvg from '@/components/custom-svg';

export default {
    name: 'Navigation',
    components: {
        'custom-svg': CustomSvg,
    },
    data: () => ({
        books: ['History', 'Fiction', 'Philosophy'],
        drawer: true,
        // Navigation Path
        logout: {
            title: 'ออกจากระบบ',
            icon: 'mdi-arrow-left-bold-circle',
        },
        changelogText: '',
        select: 1,
        confirm: false,
    }),
    props: ['navTitle'],
    computed: {
        navigationPath() {
            let userRole = this.$store.getters.userRole;
            switch (userRole) {
                case 'owner':
                    return [
                        {
                            title: 'ปฏิทิน',
                            icon: 'mdi-calendar',
                            path: '/calendar',
                        },
                        {
                            title: 'Users',
                            svg: 'outline-user',
                            path: '/users',
                        },
                        {
                            title: 'Users Dashboard',
                            svg: 'icons-users',
                            path: '/users-dashboard',
                        },
                        {
                            title: 'บทความ',
                            icon: 'mdi-newspaper',
                            path: '/content',
                        },
                        {
                            title: 'ดูดวง',
                            svg: 'outline-moon-stars',
                            path: '/fortune-telling',
                        },
                        {
                            title: 'ดูฤกษ์',
                            svg: 'outline-menu-star',
                            path: '/auspicious',
                        },
                        {
                            title: 'ดูฤกษ์ (new)',
                            svg: 'outline-menu-star',
                            path: '/auspicious2',
                        },
                        {
                            title: 'Feedback',
                            svg: 'outline-feedback',
                            path: '/feedback',
                            children: [
                                {
                                    title: 'General',
                                    path: '/feedback/general',
                                },
                                {
                                    title: 'Monthly horoscope',
                                    path: '/feedback/monthly-horoscope',
                                },
                                {
                                    title: 'Profile',
                                    path: '/feedback/profile',
                                },
                                {
                                    title: 'Day Master',
                                    path: '/feedback/day-master',
                                },
                                {
                                    title: 'Birth Element',
                                    path: '/feedback/birth-element',
                                },
                            ],
                        },
                        {
                            title: 'Ads Setting',
                            path: '/ads-setting',
                            svg: 'outline-ads',
                            children: [
                                {
                                    title: 'All Ads',
                                    path: '/ads-setting/all-ads',
                                },
                                {
                                    title: 'Ads Dashboard',
                                    path: '/ads-setting/ads-dashboard',
                                },
                                {
                                    title: 'Ads Report',
                                    path: '/ads-setting/ads-report',
                                },
                            ],
                        },
                        {
                            title: 'Stock Checking',
                            svg: 'outline-menu-stock',
                            path: '/stock-checking',
                        },
                    ];
                case 'admin':
                    return [
                        {
                            title: 'Users',
                            svg: 'outline-user',
                            path: '/users',
                        },
                        {
                            title: 'Users Dashboard',
                            svg: 'icons-users',
                            path: '/users-dashboard',
                        },
                        {
                            title: 'ดูดวง',
                            svg: 'outline-moon-stars',
                            path: '/fortune-telling',
                        },
                        {
                            title: 'ดูฤกษ์',
                            svg: 'outline-menu-star',
                            path: '/auspicious',
                        },
                        {
                            title: 'ดูฤกษ์ (new)',
                            svg: 'outline-menu-star',
                            path: '/auspicious2',
                        },
                        {
                            title: 'Feedback',
                            svg: 'outline-feedback',
                            path: '/feedback',
                            children: [
                                {
                                    title: 'General',
                                    path: '/feedback/general',
                                },
                                {
                                    title: 'Monthly horoscope',
                                    path: '/feedback/monthly-horoscope',
                                },
                                {
                                    title: 'Profile',
                                    path: '/feedback/profile',
                                },
                                {
                                    title: 'Day Master',
                                    path: '/feedback/day-master',
                                },
                                {
                                    title: 'Birth Element',
                                    path: '/feedback/birth-element',
                                },
                            ]
                        },
                        {
                            title: 'Ads Setting',
                            path: '/ads-setting',
                            svg: 'outline-ads',
                            children: [
                                {
                                    title: 'All Ads',
                                    path: '/ads-setting/all-ads',
                                },
                                {
                                    title: 'Ads Dashboard',
                                    path: '/ads-setting/ads-dashboard',
                                },
                                {
                                    title: 'Ads Report',
                                    path: '/ads-setting/ads-report',
                                },
                            ],
                        },
                    ];
                case 'content-writer':
                    return [
                        {
                            title: 'ปฏิทิน',
                            icon: 'mdi-calendar',
                            path: '/calendar',
                        },
                        {
                            title: 'บทความ',
                            icon: 'mdi-newspaper',
                            path: '/content',
                        },
                    ];
                case 'tester':
                    return [
                        {
                            title: 'Ads Setting',
                            path: '/ads-setting',
                            svg: 'outline-ads',
                            children: [
                                {
                                    title: 'All Ads',
                                    icon: 'mdi-star-three-points',
                                    path: '/ads-setting/all-ads',
                                },
                            ],
                        },
                        {
                            title: 'ดูฤกษ์ (new)',
                            svg: 'outline-menu-star',
                            path: '/auspicious2',
                        },
                         {
                            title: 'Stock Checking',
                            svg: 'outline-menu-stock',
                            path: '/stock-checking',
                        },
                    ];
            }
            return [];
        },
    },
    methods: {
        // post csv file to backend
        onSelectedFile(event) {
            if (confirm('ต้องการอัปโหลดปฏิทินหรือไม่ ?')) {
                const files = event.target.files[0];
                let formData = new FormData();
                let blob = new Blob([files], { type: 'application/csv' });
                formData.set('file', blob);
                this.$store.dispatch('uploadCSV', { formData: formData });
            }
        },

        // post multiple Lottery image to backend
        onSelectedLottery(event) {
            const files = event.target.files;
            let formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                let tempBlob = new Blob([file], { type: 'image/png' });
                formData.append('lottery', tempBlob, files[i].name);
            }
            this.$store.dispatch('uploadLottery', { formData: formData });
        },

        // trigger input
        onPickFile() {
            this.$refs.fileInput.click();
        },
        // trigger input
        onPickLottery() {
            this.$refs.fileLottery.click();
        },

        // log user out and clear Auth Data
        onLogout() {
            this.$store.dispatch('logout');
        },
        // publish calendar to mobile
        publishCalendar() {
            if (confirm('ต้องการ Publish ปฏิทินหรือไม่ ?')) {
                this.$store.dispatch('publishCalendar', {
                    changelogText: this.changelogText,
                });
            }
        },
    },
};
</script>

<style scoped>
.logout {
    align-content: flex-end;
}

div /deep/.v-list-item {
    border-radius: 8px;
}

.custom-v-list-group__header,
div /deep/.v-list-group__header {
    padding: 8px !important;
    min-height: 0px !important;
    height: 48px;
    display: flex;
    align-items: center;
}

.custom-v-list-item-icon {
    margin: 0px 12px 0px 0px !important;
}

.children-v-list-item {
    min-height: 0px !important;
    height: 32px !important;
    margin: 0px !important;
}

div /deep/.children-v-list-item .v-list-item__content {
    padding: 0px !important;
}

.nav-divider {
    margin: 8px 0px 8px 0px;
}
</style>
