import axios from '@/api/axios-date-selection2.js';
import DEFAULT_FILTER from '../../assets/date-selection-calendar/default-filter';
import { dayOfficerParse, dayConstellationParse } from '@/assets/date-selection-calendar/date-parse.js';
import {
  findMostGhost,
  findGhostInterval,
  getDateFull,
  getTomorrowDate,
  getYesterdayDate,
  isGhostChangeTomorrow,
  isChangeGhostYesterday,
  getTomorrowGhost,
  getFourSeparatingDay,
  getFourExtinctDay,
  getBreaker,
  getNoWealthDay,
  getPersonalWealth,
  getPersonalBreaker,
  getCombineAnimal,
  getSolitaryStarGroom,
  getSolitaryStarBride,
  getSkyHappinessStar,
  getYearProsperity,
  getAssetAcquire,
  getHeavenlyWealthStar,
  getPersonalExtinctionStar,
  getIllnessStar,
  getHeavenlyDoctorStar,
  getStartingDiet,
  getPersonalNobleMan,
  getChangeGhost,
  getRedContellation,
  getRedOfficer,
  isEmpty,
  getAnnualSha,
  getCalamitySha,
  getRobberySha,
  getHeavenlyVirtue,
  getMonthlyVirtue,
  getThreeHarmony,
  getDateCh,
  isDemonDay,
  isDemonMonth,
  getPigDay,
  getAvoidGhost,
  getMonthCombineAnimal,
  getMonthCrash,
  getYearCombineAnimal
} from '@/assets/date-selection-calendar/bazi-calendar-cal.js';
const state = {
  auspicious: [],
  auspiciousBazis: [],
  currentMonth: [],
  monthBetween: [],
  auspiciousMonth: [],
  personsBazi: [],
  persons: [],
  isLoadingPersons: false,
  auspiciousType: null,
  personRequestPromise: [],
  timeout: null,
  filter: [],
  checkboxKey: [],
  monthHeader: '',
};

const getters = {
  auspiciousBazis(state) {
    return state.auspiciousBazis;
  },
  currentMonth(state) {
    return state.currentMonth;
  },
  auspiciousMonth(state) {
    return state.auspiciousMonth;
  },
  personsBazi(state) {
    return state.personsBazi;
  },
  auspiciousType(state) {
    return state.auspiciousType;
  },
  monthBetween(state) {
    return state.monthBetween;
  },
  filter(state) {
    return state.filter;
  },
  monthHeader(state) {
    return state.monthHeader;
  },
  persons(state) {
    return state.persons;
  },
  checkboxFilters(state) {
    return state.filter.map((o) => o.checkboxKey);
  },
  filterAttrs(state) {
    return state.filter.map((o) => o.attrs);
  },
  goodOfficers(state) {
    let filter = state.filter;
    return filter.filter((o) => o.isGood).map((o) => o.attrs);
  },
  badOfficers(state) {
    let filter = state.filter;
    return filter.filter((o) => !o.isGood).map((o) => o.attrs);
  },
};

const mutations = {
  setAuspiciousType(state, type) {
    state.auspiciousType = type;
  },
  // Filter
  setFilter(state, filter) {
    state.filter = filter;
  },
  addFilter(state, filter) {
    state.filter = [...state.filter, ...filter];
  },
  removeFilter(state, filter) {
    state.filter = state.filter.filter((f) => !filter.map((e) => e.checkboxKey).includes(f.checkboxKey));
  },
  // Auspicious Month
  setAuspiciousBazis(state, bazis) {
    state.auspiciousBazis = bazis;
  },
  setMonthBetween(state, months) {
    state.monthBetween = months;
  },
  setCurrentMonth(state, monthData) {
    state.currentMonth = monthData;
  },
  setMonthHeader(state, header) {
    state.monthHeader = header;
  },
  setAuspiciousMonth(state, auspiciousMonthData) {
    state.auspiciousMonth = auspiciousMonthData;
  },
  // Person
  setPersons(state, persons) {
    state.persons = persons;
  },
  clearPersons(state) {
    state.persons = [];
  },
  togglePersonActive(state, index) {
    state.persons[index].isActive = !state.persons[index].isActive;
  },
};

const actions = {
  getAuspiciousCalendar: async ({ commit, getters, dispatch }, request) => {
    let promises = [];
    let { request2 } = getters;
    let { selected_auspicious } = request2;
    let requireBazi = selected_auspicious.reduce((acc, curr) => acc.concat(curr), []).map((r) => r.date).filter(r => r.date === '')
    let requireBaziSet = [...new Set([...requireBazi])]
    // Gather Month to find Month Header
    let currentMonth = [];
    let monthBetween = [];
    let monthHeaderInterval = []
    currentMonth = getters.calendar.filter((item) => {
      return (
        (new Date(item.date).getMonth() == request.month - 1 && new Date(item.date).getFullYear() == request.year) ||
        requireBaziSet.includes(item.date)
      );
    });
    monthBetween = getters.calendar.filter((item) => {
      let monthBefore = new Date(item.date).getMonth() + 2 == request.month && new Date(item.date).getFullYear() == request.year;
      let monthCurrent = new Date(item.date).getMonth() + 1 == request.month && new Date(item.date).getFullYear() == request.year;
      let monthAfter = new Date(item.date).getMonth() == request.month && new Date(item.date).getFullYear() == request.year;
      return monthBefore || monthCurrent || monthAfter;
    });

    // Find Month Header
    let MycurrentMonth = getters.calendar.filter((item) => {
      let monthCurrent = new Date(item.date).getMonth() + 1 == request.month && new Date(item.date).getFullYear() == request.year;
      return monthCurrent;
    });

    let addupCurrentGhost = MycurrentMonth.map((r) => {
      return {
        ...r,
        ghost_festival_year_ch: r.ghost_festival_year_ch % 2 == 0 ? r.ghost_festival_year_ch - 1 : r.ghost_festival_year_ch,
      };
    });
    let addupBetweenGhost = monthBetween.map((r) => {
      return {
        ...r,
        ghost_festival_year_ch: r.ghost_festival_year_ch % 2 == 0 ? r.ghost_festival_year_ch - 1 : r.ghost_festival_year_ch,
      };
    });
    // Get Bazi for Month Header
    let ghost = findMostGhost(addupCurrentGhost);
    monthHeaderInterval = findGhostInterval(addupBetweenGhost, ghost);
    let tomorrow = getTomorrowDate(monthHeaderInterval[0])
    requireBaziSet.push(tomorrow)
    // Find Bazi of selected data
    let selectedBaziData = [];
    if (Array.isArray(selected_auspicious) && !requireBaziSet.includes('')) {
      requireBaziSet.forEach((e, index) => {
        let promise = new Promise((resolve, reject) => {
          let params = {
            date: e,
            time: null,
          };
          if (e !== null) {
            axios
              .get(`https://v2.api.numeiang.app/v2/calendar/admin/bazi`, { params: params })
              .then((res) => {
                resolve({
                  ...res.data,
                });
              })
              .catch((err) => {
                reject({ ...e });
              });
          } else {
            resolve({
              date: e,
              bazi: null,
            });
          }
        });
        promises.push(promise);
      });
      await Promise.allSettled(promises).then((data) => {
        selectedBaziData = data.map((b) => b.value);
      });
    }
    let baziData = []
    await axios
      .get('https://v2.api.numeiang.app/admin/v2/calendar/bazi', {
        params: { year: request.year, month: request.month },
      })
      .then((res) => {
        baziData = res.data;
        baziData = [...baziData, ...selectedBaziData];
        function getRemapBazi(bazi) {
          for (const key in bazi) {
            if (bazi[key] == null) continue;
            if (typeof bazi[key] !== 'object') continue;
            bazi[key] = {
              ...bazi[key],
              god: bazi[key].heavenly_stem.ten_god,
              animal: {
                id: bazi[key].earthly_branch.id,
                mandarin: bazi[key].earthly_branch.mandarin,
                name: bazi[key].earthly_branch.name_en,
                name_en: bazi[key].earthly_branch.name_en,
                name_th: bazi[key].earthly_branch.name_th,
                pinyin: bazi[key].earthly_branch.pinyin,
              },
            };
          }
          return bazi;
        }
        baziData = baziData.map((b) => {
          return getRemapBazi(b);
        });
        commit('setAuspiciousBazis', baziData);
        let tomorrow = getTomorrowDate(monthHeaderInterval[0])
        // Use Tomorrow bazi because of bazi time at midnight might be incorrect, use tomorrow for safety
        let startBazi = baziData.filter((b) => b.date === tomorrow)[0];
        let { month } = startBazi;
        let { mandarin: heavenly_stem } = month.heavenly_stem;
        let { mandarin: earthly_branch } = month.earthly_branch;

        let { full_name_en: el_name_en } = month.heavenly_stem.element;
        let { name_en: animal_name_en } = month.earthly_branch;
        let monthHeader = `${getDateFull(monthHeaderInterval[0])} - ${getDateFull(
          monthHeaderInterval[1]
        )} ${heavenly_stem}${earthly_branch} ${el_name_en} ${animal_name_en}`;
        commit('setMonthHeader', monthHeader);
        commit('setCurrentMonth', currentMonth);
        commit('setMonthBetween', monthBetween);
        dispatch('calculateAuspiciousMonth');
      });
  },
  calculateAuspiciousMonth: ({ dispatch, commit, getters }) => {
    let bazis = getters.auspiciousBazis;
    let persons = getters.persons.filter((p) => p.bazi !== null);
    let monthBetween = getters.monthBetween; // use to find Ghost before month / after month
    let auspiciousMonth = getters.currentMonth.map((data) => {
      // find important day
      let dateList = {
        important_buddhist_day_th: data?.important_buddhist_day_th || null,
        important_day_name_th_ch: data?.important_day_name_th_ch || null,
        important_day_th: data?.important_day_th || null,
        auspicious_day: data?.auspicious_day ? 'วันธงชัย' : null,
        inauspicious_day: data?.inauspicious_day ? 'วันกาลกิณี' : null,
        wicked_day: data?.wicked_day ? 'วันอัปมงคล' : null,
        buddhist_day_ch: data?.buddhist_day_ch ? 'วันพระจีน' : null,
      };
      let important_day = [];
      for (const [key, value] of Object.entries(dateList)) {
        if (!isEmpty(value)) important_day.push(value);
      }
      // get date bazis
      let bazi = bazis.filter((e) => e.date === data.date)[0];
      let { date, day_ch, month_ch, ghost_festival_year_ch, day_officer, day_constellation, hour_code, day_bazi_pattern } = data;
      // Change ghost festival day
      let isRedOfficer = getRedOfficer(day_officer);
      let isRedConstellation = getRedContellation(day_constellation);
      let isAvoidDay = isDemonDay(day_ch);
      let isAvoidMonth = isDemonMonth(day_ch, month_ch);
      let isAvoidGhost = getAvoidGhost(ghost_festival_year_ch);
      let fourSeparating = null;
      let fourExtinct = null;
      let tomorrow = getTomorrowDate(data.date);
      // if Ghost Change Tomorrow -> possible fourSeparating / fourExtinction
      if (isGhostChangeTomorrow(monthBetween, tomorrow, ghost_festival_year_ch)) {
        let tomorrowGhost = getTomorrowGhost(monthBetween, tomorrow);
        fourSeparating = getFourSeparatingDay(tomorrowGhost);
        fourExtinct = getFourExtinctDay(tomorrowGhost);
      }

      let yesterday = getYesterdayDate(data.date);
      // to find if UI should show Ghost on the day
      let change_ghost_day = isChangeGhostYesterday(monthBetween, yesterday, ghost_festival_year_ch) ? getChangeGhost(ghost_festival_year_ch) : null;

      // convert day_ch to Chinese Character
      let date_ch = getDateCh(day_ch, month_ch);
      // --------------------- / -------- -----------------------
      // Prep
      let dayPillarElement = `${bazi.day.heavenly_stem.mandarin} ${bazi.day.heavenly_stem.element.name_en}`;
      let dayPillarAnimal = `${bazi.day.animal.mandarin} ${bazi.day.animal.name_en}`;
      day_officer = dayOfficerParse[day_officer];
      day_constellation = dayConstellationParse[day_constellation];
      // --------------------- / -------------------------------
      // Bazi Calculation
      let { day, month, year } = bazi;
      // destructure object and rename
      let { pattern: day_pattern } = day;
      let { mandarin: day_heaven } = day.heavenly_stem;
      // let { heavenly_stem: month_heaven } = month;
      // let { heavenly_stem: year_heaven, pattern: year_pattern } = year;
      let { pattern: year_pattern } = year;
      let { mandarin: year_heaven } = year.heavenly_stem;
      let { id: day_animal } = day.animal;
      let { id: month_animal } = month.animal;
      let { id: year_animal } = year.animal;
      let personOneAnimal = null;
      let personTwoAnimal = null;
      // Find Star relate to person animal / heaven
      let personsAuspicious = persons.map((p, index) => {
        let { day, month, year } = p.bazi;
        let { heavenly_stem: person_day_heaven } = day;
        // let { mandarin: person_day_heaven } = day.heavenly_stem;
        // let { heavenly_stem: person_month_heaven } = month;
        // let { heavenly_stem: person_year_heaven } = year;
        // let { code: person_day_animal } = day.animal;
        let { code: person_month_animal } = month.animal;
        let { code: person_year_animal } = year.animal;
        if (index == 0) personOneAnimal = person_year_animal; // first person
        if (p.title == 'เจ้าบ่าว') personOneAnimal = person_year_animal;
        if (p.title == 'เจ้าสาว') personTwoAnimal = person_year_animal;
        if (p.title == 'พ่อของเจ้าสาว' || p.title == 'แม่ของเจ้าสาว' || p.title == 'พ่อของเจ้าบ่าว' || p.title == 'แม่ของเจ้าบ่าว') {
          return {
            title: p.title,
            acronym: p.acronym,
            personalBreaker: getPersonalBreaker(person_year_animal, day_animal),
          };
        }

        return {
          title: p.title,
          acronym: p.acronym,
          personYearAnimal: person_year_animal,
          personalWealth: getPersonalWealth(person_year_animal, day_animal),
          personalBreaker: getPersonalBreaker(person_year_animal, day_animal),
          combineAnimal: getCombineAnimal(person_year_animal, day_animal),
          solitaryStarGroom: p.title == 'เจ้าบ่าว' ? getSolitaryStarGroom(person_year_animal, day_animal) : null,
          solitaryStarBride: p.title == 'เจ้าสาว' ? getSolitaryStarBride(person_year_animal, day_animal) : null,
          heavenlyWealthStar: getHeavenlyWealthStar(person_day_heaven, day_heaven),
          personalExtinctionStar: getPersonalExtinctionStar(person_day_heaven, day_animal),
          heavenlyDoctorStar: getHeavenlyDoctorStar(person_month_animal, day_animal),
          startingDiet: getStartingDiet(person_year_animal, day_animal),
          personalNobleMan: getPersonalNobleMan(person_day_heaven, day_animal),
          monthCrash: getMonthCrash(person_month_animal, month_animal)
        };
      });
      // Add Acronym
      personsAuspicious = personsAuspicious.map((p) => {
        let acronym = p.acronym;
        let addedAcronym = Object.fromEntries(
          Object.entries(p).map(([key, value]) => {
            if (value == null || key == 'id' || key == 'title' || key == 'acronym' || key == 'personYearAnimal') return [key, value];
            return [key, `${acronym} ${value}`];
          })
        );
        return addedAcronym;
      });
      // Find Date Star
      let isPigDay = getPigDay(day_animal);
      let noWealthDay = getNoWealthDay(year_pattern, day_pattern);
      let yearBreaker = getBreaker(year_animal, day_animal, 'Year');
      let monthBreaker = getBreaker(month_animal, day_animal, 'Month');
      let combineAnimalYear = getYearCombineAnimal(year_animal, day_animal);
      let combineAnimalMonth = getMonthCombineAnimal(month_animal, day_animal);
      let skyHappinessStar = getSkyHappinessStar(month_animal, day_animal);
      let yearProsperity = getYearProsperity(year_heaven, day_animal);
      let assetAcquire = getAssetAcquire(year_heaven, day_animal);
      let illnessStar = getIllnessStar(year_animal, day_animal);
      let robberySha = getRobberySha(year_animal, day_animal);
      let calamitySha = getCalamitySha(year_animal, day_animal);
      let annualSha = getAnnualSha(year_animal, day_animal);
      let heavenlyVirtue = getHeavenlyVirtue(month_animal, day_heaven, day_animal);
      let monthlyVirtue = getMonthlyVirtue(month_animal, day_heaven);
      let threeHarmony = getThreeHarmony(day_animal, personOneAnimal, personTwoAnimal);
      let star = {
        isAvoidGhost,
        noWealthDay,
        robberySha,
        calamitySha,
        annualSha,
        fourSeparating,
        fourExtinct,
        yearBreaker,
        monthBreaker,
        combineAnimalYear,
        combineAnimalMonth,
        // killing,
        skyHappinessStar,
        yearProsperity,
        assetAcquire,
        illnessStar,
        heavenlyVirtue,
        monthlyVirtue,
        threeHarmony,
        isAvoidDay,
        isAvoidMonth,
        isPigDay,
      };
      return {
        date,
        date_ch,
        dayPillarElement,
        dayPillarAnimal,
        important_day,
        change_ghost_day,
        dateList,
        day_bazi_pattern,
        hour_code,
        isRedOfficer,
        isRedConstellation,
        personalStar: personsAuspicious,
        star: star,
        day_animal,
        month_animal,
        year_animal,
        day_officer,
        day_constellation,
        day_heaven,
      };
    });
    commit('setAuspiciousMonth', auspiciousMonth);
    dispatch('initAuspicious');
  },
  updatePersonFilter: ({ commit }, index) => {
    commit('togglePersonActive', index);
  },
  // Filter
  setFilter: async ({ commit }, filter) => {
    commit('setFilter', filter);
  },
  addFilter: ({ commit }, filters) => {
    commit('addFilter', filters);
  },
  updateFilter: ({ commit, getters }, { attrs, checkboxKey, isGood, value }) => {
    let filters = getters.filter;
    if (value === false) {
      filters = filters.filter((k) => k.checkboxKey !== checkboxKey);
    } else {
      filters.push({ attrs, checkboxKey, isGood });
    }
    commit('setFilter', filters);
  },
  removeFilter: ({ commit }, filters) => {
    commit('removeFilter', filters);
  },
  clearFilter: ({ commit }) => {
    commit('setFilter', DEFAULT_FILTER);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

function haveEmptyProperty(obj) {
  for (const key in obj) {
    // skip info obj
    if (key == 'wallet_info') continue;
    if (typeof obj[key] === 'object') {
      if (haveEmptyProperty(obj[key])) {
        return true; // Found an empty property in the nested object
      }
    } else {
      if (obj[key] === '') {
        return true; // Found an empty property
      }
    }
  }
  return false; // No empty properties found
}
