import store from '@/store/index.js';
import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
Vue.use(VueAxios, axios);

const routes = [
    {
        path: '/',
        redirect: '/signin',

    },
    {
        path: '/signin',
        name: 'signin',
        meta: {
            allowRoles: ['All'],
            requireAuth: false,
        },
        component: () => import('../views/login/sign-in.vue'),
    },
    {
        path: '/calendar',
        name: 'ปฏิทิน',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
        },
        component: () => import('../views/calendar/calendar.vue'),
    },
    {
        path: '/calendar-history',
        name: 'ประวัติปฏิทิน',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
        },
        component: () => import('../views/calendar/calendar-history.vue'),
    },
    {
        path: '/users',
        name: 'All Users',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
        },
        component: () => import('../views/users/users.vue'),
    },
    {
        path: '/fortune-telling',
        name: 'ดูดวง',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
        },
        component: () => import('../views/fortune-telling.vue'),
    },
    {
        path: '/auspicious',
        name: 'ดูฤกษ์',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
        },
        component: () => import('../views/date-selection/index.vue'),
    },
    {
        path: '/auspicious/date-selection/request',
        name: 'คำร้องขอดูฤกษ์',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
        },
        component: () => import('../views/date-selection/request.vue'),
    },
    {
        path: '/auspicious/date-selection/auspicious-selection',
        name: 'Auspicious Selection',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
        },
        component: () =>
            import('../views/date-selection/auspicious-selection.vue'),
    },
    {
        path: '/auspicious2',
        name: 'ดูฤกษ์2',
        meta: {
            allowRoles: ['owner', 'admin', 'tester'],
            requireAuth: true,
            aliasName: 'ดูฤกษ์',
        },
        component: () => import('../views/date-selection2/index.vue'),
    },
    {
        path: '/auspicious2/date-selection/request',
        name: 'คำร้องขอดูฤกษ์2',
        meta: {
            allowRoles: ['owner', 'admin', 'tester'],
            requireAuth: true,
            aliasName: 'คำร้องขอดูฤกษ์',
        },
        component: () => import('../views/date-selection2/request.vue'),
    },
    {
        path: '/auspicious2/date-selection/candidate-selection',
        name: 'Candidate Selection2',
        meta: {
            allowRoles: ['owner', 'admin', 'tester'],
            requireAuth: true,
            aliasName: 'Candidate Selection',
        },
        component: () =>
            import('../views/date-selection2/candidate-selection.vue'),
    },
    {
        path: '/auspicious2/date-selection/auspicious-selection',
        name: 'Auspicious Selection2',
        meta: {
            allowRoles: ['owner', 'admin', 'tester'],
            requireAuth: true,
            aliasName: 'Auspicious Selection',
        },
        component: () =>
            import('../views/date-selection2/auspicious-selection.vue'),
    },
    {
        path: '/content',
        name: 'บทความ',
        meta: {
            allowRoles: ['owner', 'admin', 'content-writer'],
            requireAuth: true,
        },
        component: () => import('../views/content/content.vue'),
    },
    {
        path: '/content/add',
        name: 'เพิ่มบทความ',
        meta: {
            allowRoles: ['owner', 'admin', 'content-writer'],
            requireAuth: true,
        },
        component: () => import('../views/content/edit-content.vue'),
    },
    {
        path: '/content/:id',
        name: 'แก้ไขบทความ',
        meta: {
            allowRoles: ['owner', 'admin', 'content-writer'],
            requireAuth: true,
        },
        component: () => import('../views/content/edit-content.vue'),
    },
    {
        path: '/users-dashboard',
        name: 'User Dashboard',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
        },
        component: () => import('../views/users/users-dashboard.vue'),
    },
    {
        path: '/ads-setting/all-ads',
        name: 'All Ads',
        meta: {
            allowRoles: ['owner', 'admin', 'tester'],
            requireAuth: true,
            aliasName: 'Ads Setting',
        },
        component: () => import('../views/ads/all-ads.vue'),
    },
    {
        path: '/ads-setting/add-ads',
        name: 'Add Ads',
        meta: {
            allowRoles: ['owner', 'admin', 'tester'],
            requireAuth: true,
            aliasName: 'Ads Setting',
        },
        component: () => import('../views/ads/add-ads.vue'),
    },
    {
        path: '/ads-setting/edit-ads',
        name: 'Edit Ads Setting',
        meta: {
            allowRoles: ['owner', 'admin', 'tester'],
            requireAuth: true,
            aliasName: 'Ads Setting',
        },
        component: () => import('../views/ads/edit-ads.vue'),
    },
    {
        path: '/ads-setting/ads-report',
        name: 'Ads Report',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
            aliasName: 'Ads Setting',
        },
        component: () => import('../views/ads/ads-report.vue'),
    },
    {
        path: '/ads-setting/ads-dashboard',
        name: 'Ads Dashboard',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
            aliasName: 'Ads Setting',
        },
        component: () => import('../views/ads/ads-dashboard.vue'),
    },
    {
        path: '/stock-checking',
        name: 'Stock Checking',
        meta: {
            allowRoles: ['owner', 'tester'],
            requireAuth: true,
            aliasName: 'Stock Checking',
        },
        component: () => import('../views/stock-checking/stock-checking.vue'),
    },
    {
        path: '/feedback/general',
        name: 'Feedback',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
            aliasName: 'General Feedback',
        },
        component: () => import('../views/feedback/general.vue'),
    },
    {
        path: '/feedback/monthly-horoscope',
        name: 'Monthly horoscope',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
        },
        component: () => import('../views/feedback/monthly-horoscope.vue'),
    },
    {
        path: '/feedback/profile',
        name: 'Profile',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
        },
        component: () => import('../views/feedback/profile.vue'),
    },
    {
        path: '/feedback/day-master',
        name: 'Day Master',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
        },
        component: () => import('../views/feedback/day-master.vue'),
    },
    {
        path: '/feedback/birth-element',
        name: 'Birth Element',
        meta: {
            allowRoles: ['owner', 'admin'],
            requireAuth: true,
        },
        component: () => import('../views/feedback/birth-element.vue'),
    },
];

const router = new VueRouter({
    routes,
});

export const allowRoutes = {
    owner: {
        allow: routes.map((r) => r.name),
        defaultRedirectPath: '/fortune-telling',
    },
    'content-writer': {
        allow: ['บทความ', 'เพิ่มบทความ', 'แก้ไขบทความ'],
        defaultRedirectPath: '/content',
    },
    admin: {
        allow: [
            'All Users',
            'ดูดวง',
            'ดูฤกษ์',
            'Auspicious Selection',
            'ดูฤกษ์2',
            'คำร้องขอดูฤกษ์2',
            'Candidate Selection2',
            'Auspicious Selection2',
            'บทความ',
            'เพิ่มบทความ',
            'แก้ไขบทความ',
            'Dashboard',
            'All Ads',
            'Ads Setting',
            'Ads Dashboard',
            'Ads Report',
            'Feedback'
        ],
        defaultRedirectPath: '/users',
    },
    tester: {
        allow: [
            'All Ads',
            'Ads Setting',
            'ดูฤกษ์2',
            'คำร้องขอดูฤกษ์2',
            'Candidate Selection2',
            'Auspicious Selection2',
        ],
        defaultRedirectPath: '/ads-setting/all-ads',
    },
    write: {
        allow: [],
        defaultRedirectPath: '/content',
    },
};

router.beforeEach(async (to, from, next) => {
    let { status } = await store.dispatch('checkCredential');
    if (status == 'error' && to.name == 'signin') {
        next();
    } else {
        if (status == 'success') {
            if (!isInitialize)
                await store.dispatch('initializeUserFromLocalStorage');
            let { isInitialize, userRole: role } = store.getters;
            let roleDefaultRedirectPath = allowRoutes[role].defaultRedirectPath;

            // let roleAllowRoutes = allowRoutes[role].allow;
            // if (!roleAllowRoutes.includes(to.name)) {
            if (to.meta.requireAuth && !to.meta.allowRoles.includes(role)) {
                next(roleDefaultRedirectPath);
            } else {
                if (to.name == 'signin') {
                    next(roleDefaultRedirectPath);
                } else {
                    next();
                }
            }
        } else {
            next('/signin');
        }
    }
});
export default router;
