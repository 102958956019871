import axios from '@/api/axios-date-selection2.js';

const state = {
  selectedAuspicious: [],
  currentAuspicious: [],
  editingIndex: -1,
  auspiciousSelect: [],
  selectedAuspicious: [],
  remark: '',
};

const getters = {
  currentAuspicious(state) {
    return state.currentAuspicious;
  },
  isEditing(state) {
    return state.editingIndex >= 0;
  },
  editingIndex(state) {
    return state.editingIndex;
  },
  selectedAuspicious(state) {
    return state.selectedAuspicious;
  },
  remark(state) {
    return state.remark;
  },
};

const mutations = {
  // Mutate
  setCurrentAuspicious(state, data) {
    state.currentAuspicious = data;
  },
  setRemark(state, remark) {
    state.remark = remark
  },
  addKey(state, obj) {
    state.selectedAuspicious[0][obj.index][obj.key] = obj.value;
  },
  addSuggestion(state, obj) {
    state.selectedAuspicious[0][obj.index]['remark'] = obj.value;
  },
  selectEditAuspicious(state, index) {
    state.editingIndex = index;
  },
  unselectEditAuspicious(state) {
    state.editingIndex = -1;
  },
  // Add
  addSelectedAuspicious(state, data) {
    state.selectedAuspicious.push(data);
  },
  // Edit
  editAuspiciousSelect(state, index, data) {
    state.auspiciousSelect[index] = data;
  },
  replaceSelectedAuspicious(state, data, index) {
    state.selectedAuspicious.splice(index, 1, data);
  },
  // Delete
  deleteAuspicious(state, index) {
    state.selectedAuspicious.splice(index, 1);
  },
  // Clear Util
  clearSelectedAuspicious(state) {
    state.selectedAuspicious = [];
  },
};

const actions = {
  // Initialization
  initAuspicious: ({ dispatch, getters }) => {
    let { selected_auspicious, status } = getters.request2;
    let { submitType } = getters;
    if (submitType == 'auspicious' && !['completed'].includes(status)) return;
    if (submitType == 'candidate' && !['date_confirming', 'completing', 'completed'].includes(status)) return
    // if (submitType == 'candidate' && ['date_selecting'].includes(status)) return
    if (selected_auspicious !== null) {
      for (var item of selected_auspicious) {
        dispatch('addAuspicious', item);
      }
    }
  },
  initCurrentAuspicious: ({ getters, commit }) => {
    let { auspiciousType, request2 } = getters;
    let { selected_auspicious } = request2;
    if (selected_auspicious == null) return;
    let aus = selected_auspicious[0];
    if (Array.isArray(aus)) {
      aus = aus.map((a) => {
        return { ...a, date: new Date().toISOString().slice(0, 10), time: null };
      });
      commit('setCurrentAuspicious', aus);
    }
  },
  addAuspicious: ({ commit, getters, dispatch }, data) => {
    let { auspiciousMonth, selectedAuspicious } = getters;
    if (selectedAuspicious.length >= 3) {
      dispatch('setAlertMessage', { message: 'สามารถเพิ่มได้สูงสุด 3 ฤกษ์', type: 'warning' });
      return
    }
    if (data == null) return;
    if (auspiciousMonth.length === 0) return;
    let dateWithAuspiciousList = data.map((a) => {
      let ausDate = auspiciousMonth.find((d) => d.date == a.date);
      let dateWithAuspiciousList = { ...a };
      if (typeof ausDate !== 'undefined') {
        let { star, personalStar, dateList, day_officer, day_constellation } = ausDate;
        let list = getAuspiciousList([dateList, star, ...personalStar]);
        dateWithAuspiciousList = { ...a, ausList: { list, day_officer, day_constellation } };
      }
      return {
        ...dateWithAuspiciousList,
      };
    });
    commit('addSelectedAuspicious', dateWithAuspiciousList);
    dispatch('clearCurrentAuspicious');
  },
  //   Clear workflow
  clearCurrentAuspicious: ({ getters, commit }) => {
    let { request2, selectedAuspicious } = getters;
    let { selected_auspicious } = request2;
    let emptyAuspicious = selectedAuspicious[0];
    emptyAuspicious = emptyAuspicious.map((a) => {
      return {
        ...a,
        time: null,
      };
    });
    commit('setCurrentAuspicious', emptyAuspicious);
  },
  setCurrentAuspicious: ({ commit, getters }, data) => {
    let { auspiciousMonth } = getters;
    let { date } = data;
    // Find Star From date
    let dateWithAuspiciousList = data.map((a) => {
      let ausDate = auspiciousMonth.find((d) => d.date == a.date);
      let dateWithAuspiciousList = { ...a };
      if (typeof ausDate !== undefined) {
        let { star, personalStar, dateList, day_officer, day_constellation } = ausDate;
        let list = getAuspiciousList([dateList, star, ...personalStar]);
        dateWithAuspiciousList = { ...a, ausList: { list, day_officer, day_constellation } };
      }
      return {
        ...dateWithAuspiciousList,
      };
    });
    commit('setCurrentAuspicious', dateWithAuspiciousList);
  },
  //   EDIT SECTION
  //   ON Confirm EDIT Day Pillar Select
  replaceEditWithCurrentAuspicious: ({ commit, getters, dispatch }) => {
    let editingIndex = getters.editingIndex;
    let currentAuspicious = getters.currentAuspicious;
    commit('replaceSelectedAuspicious', currentAuspicious, editingIndex);
    commit('unselectEditAuspicious');
    dispatch('clearCurrentAuspicious');
  },
  selectEditAuspicious: ({ commit, getters }, index) => {
    commit('selectEditAuspicious', index);
    let currentEdit = getters.selectedAuspicious[index];
    commit('setCurrentAuspicious', currentEdit);
  },
  //   DELETE
  deleteAuspicious: ({ commit, getters }, index) => {
    commit('deleteAuspicious', index);
    let editingIndex = getters.editingIndex;
    if (index == editingIndex) {
      commit('unselectEditAuspicious');
    }
  },
  // UnSelect and Clear current auspicious
  unselectEditAuspicious: ({ commit, dispatch }) => {
    commit('unselectEditAuspicious');
    dispatch('clearCurrentAuspicious');
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

function getAuspiciousList(list) {
  function getNonNullStrings(data) {
    const nonNullStrings = [];
    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        // Exclude properties like title, acronym, and personYearAnimal
        if (key !== 'title' && key !== 'acronym' && key !== 'personYearAnimal' && item[key] !== null) {
          nonNullStrings.push(item[key]);
        }
      });
    });
    return nonNullStrings;
  }
  const nonNullPropertiesList = getNonNullStrings(list);
  return nonNullPropertiesList;
}
