// use in god-gauge
// parse god code
const godData = [
  {
    name: "正印,DR,Direct Resource",
    detail: `
    ㆍPatience and forbearance shall feature amongst one's most prominent virtues
    ㆍIndicates someone who knows how to save and spend one's wealth wisely, without tilting the scale to either extreme
    ㆍIndicates someone who is tolerant and merciful in dealing with others, especially disadvantaged parties
    ㆍKnows how to enjoy life
    ㆍEnjoys amassing knowledge
  `,
  },
  {
    name: "偏印,IR,Indirect Resource",
    detail: `
    ㆍEnhances the  sixth sense' or ‘paranormal intuition' of individuals
    ㆍIntuitive
    ㆍEmotional
    ㆍReligious and superstitious
`,
  },
  {
    name: "劫财,RW,Rob Wealth",
    detail: `
    ㆍCertain circumstances or people may deprive you of some of your strengths, particularly when you least expect them to
    ㆍCompetitive
    ㆍBrave
    ㆍQuick witted
    ㆍConfident
  `,
  },
  {
    name: "比肩,F,Friend",
    detail: `
    ㆍThere will always be plenty of noble people to support and complement you, especially in times of need
    ㆍPossess strong willpower and confidence
    ㆍHelpful
    ㆍInitiative
    ㆍSelf-centered
`,
  },
  {
    name: "伤官,HO,Hurting Officer",
    detail: `
    ㆍOutstanding achievements in academic or career endeavors
    ㆍA smooth-sailing life, with challenges that may be surmounted with one's capabilities
    ㆍExcellent academic performance and achievements
    ㆍOne's special talents and skills will be noticed, acknowledged and rewarded accordingly
    ㆍMost of life's endeavors will proceed smoothly, with minor hiccups or untoward incidents

  `,
  },
  {
    name: "食神,EG,Eating God",
    detail: `
    ㆍWisdom
    ㆍIntelligence
    ㆍAcademic and scholastic excellence
    ㆍFor expectant mothers, it could indicate a smooth and trouble-free delivery of their babies
    ㆍQuiet, eccentric and secretive
  `,
  },
  {
    name: "正财,DW,Direct Wealth",
    detail: `
    ㆍTolerance and forbearance shall feature strongly amongst one's personal virtues
    ㆍBright career prospects for salaried workers, and excellent business prospects for entrepreneurs
    ㆍIndicates someone who is thrifty, yet prospects for entrepreneurs knows how to utilize his/her wealth to enjoy life to the fullest
    ㆍFor a man, it indicates a harmonious relationship between husband and wife
    ㆍRepresents one's salary and residual income 
  `,
  },
  {
    name: "偏财,IW,Indirect Wealth",
    detail: `
    ㆍExcellent financial prospects, coupled with ability to amass and accumulate wealth
    ㆍGood Entrepreneurial talents
    ㆍAn indicator of a close, intimate relationship with one's father
    ㆍAn indicator that the time is ripe to tie the knot
    ㆍExcellent business acumen
    ㆍRepresents one's investments
`,
  },
  {
    name: "正官,DO,Direct Officer",
    detail: `
    ㆍGood career prospects, with the ability to find a suitable job position that matches one's capabilities and preferences
    ㆍFor a woman, it indicates her ability to find a suitable partner or husband with relative ease
    ㆍAn enhancement of one's reputation and status, as well as goodwill with others
    ㆍAn indicator of someone who knows how to strike a balance between 'give and take, without trying to please everyone to his/her own detriment
    ㆍDisciplined & principled
    ㆍWell mannered
    ㆍInflexible
  `,
  },
  {
    name: "七殺,SK,Seven Killings",
    detail: `
    ㆍAn elevation or rise in authority, power and status
    ㆍIndicates courage and bravery, without compromising on wisdom
    ㆍHasty
    ㆍLoves to win
    ㆍBold & daring
  `,
  },
];

export default godData;
