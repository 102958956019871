const dayFilter = [
  {
    attrs: 'important_day_th',
    checkboxKey: 'important_day_th',
  },
  {
    attrs: 'important_buddhist_day_th',
    checkboxKey: 'important_buddhist_day_th',
  },
  {
    attrs: 'important_day_name_th_ch',
    checkboxKey: 'important_day_name_th_ch',
  },
  {
    attrs: 'auspicious_day',
    checkboxKey: 'auspicious_day',
  },
  {
    attrs: 'wicked_day',
    checkboxKey: 'wicked_day',
  },
  {
    attrs: 'inauspicious_day',
    checkboxKey: 'inauspicious_day',
  },
  {
    attrs: 'buddhist_day_ch',
    checkboxKey: 'buddhist_day_ch',
  },
];

const floatStarFilter = [
  {
    attrs: 'fourSeparating',
    checkboxKey: 'fourSeparating',
  },
  {
    attrs: 'fourExtinct',
    checkboxKey: 'fourExtinct',
  },
  {
    attrs: 'yearBreaker',
    checkboxKey: 'yearBreaker',
  },
  {
    attrs: 'monthBreaker',
    checkboxKey: 'monthBreaker',
  },
  {
    attrs: 'day_breaker_time',
    checkboxKey: 'day_breaker_time',
  },
  {
    attrs: 'month_breaker_time',
    checkboxKey: 'month_breaker_time',
  },
  {
    attrs: 'year_breaker_time',
    checkboxKey: 'year_breaker_time',
  },
  {
    attrs: 'user_combine_animal_time',
    checkboxKey: 'user_combine_animal_time',
  },
  {
    attrs: 'personalBreaker',
    checkboxKey: 'personal-personalBreaker',
  },
  {
    attrs: 'personalBreakerTime',
    checkboxKey: 'personal-personalBreakerTime',
  },
  {
    attrs: 'isAvoidDay',
    checkboxKey: 'isAvoidDay',
  },
  {
    attrs: 'isAvoidMonth',
    checkboxKey: 'isAvoidMonth',
  },
  {
    attrs: 'isAvoidGhost',
    checkboxKey: 'isAvoidGhost',
  },
  {
    attrs: 'monthCrash',
    checkboxKey: 'personal-monthCrash',
  },
];

export default [...dayFilter, ...floatStarFilter]

// export default [
//   'auspicious_day',
//   'important_buddhist_day_th',
//   'important_day_name_th_ch',
//   'important_day_th',
//   'inauspicious_day',
//   'wicked_day',

//   'fourSeparating',
//   'fourExtinct',

//   'personalBreaker',
//   'personalBreakerTime',

//   'yearBreaker',
//   'monthBreaker',

//   'day_breaker_time',
//   'month_breaker_time',
//   'year_breaker_time',
//   // 'assetAcquire',
//   // 'illnessStar',
//   // 'robberySha',
//   // 'calamitySha',
//   // 'annualSha',
//   // 'monthBreaker',
//   // 'skyHappinessStar',
//   // 'yearProsperity',

//   // 'combineAnimal',
//   // 'heavenlyDoctorStar',
//   // 'heavenlyWealthStar',
//   // 'noWealthDay',
//   // 'personalBreaker',
//   // 'personalExtinctionStar',
//   // 'personalNobleMan',
//   // 'personalWealth',
//   // 'solitaryStarBride',
//   // 'solitaryStarGroom',
//   // 'startingDiet',
// ];