import {
    dayBranchResult,
    dayStemTableResult,
    getMonthBranchResult,
    getYearBranchResult,
    zodiacResult,
    zodiacResult2
} from './result'

import {
    dayBranchTable,
    dayStemTable,
    getMonthBranchTable,
    getYearBranchTable,
    zodiacTable,
    zodiacTable2
} from './table'

// Annual Bazi Calculation Function
// Calculation Document https://docs.google.com/spreadsheets/d/1b44USrurltm-7tktFhXHhw5J5lA-H-AP-DAwzEzb60I/edit#gid=673599668
// Calculation Table / Result is in constants/fortune-telling.js
export const getYearBranch = (natal) => {
    // ดวงชะตาที่มีเคราห์ in excel
    let currentYearZodiac = natal.currentYearNatal.animal.mandarin;
    let hourZodiac = natal.birthNatal.hour?.animal.mandarin;
    let dayZodiac = natal.birthNatal.day.animal.mandarin;
    let monthZodiac = natal.birthNatal.month.animal.mandarin;
    let yearZodiac = natal.birthNatal.year.animal.mandarin;

    let branch = {
        hourBranch: [],
        dayBranch: [],
        monthBranch: [],
        yearBranch: [],
    }

    if (natal.birthNatal.hour !== null) {
        getYearBranchTable[currentYearZodiac].forEach((element, index) => {
            element === hourZodiac ? branch.hourBranch.push(getYearBranchResult[index]) : null
        });
    }
    // Day
    getYearBranchTable[currentYearZodiac].forEach((element, index) => {
        element === dayZodiac ? branch.dayBranch.push(getYearBranchResult[index]) : null
    });

    // Month
    getYearBranchTable[currentYearZodiac].forEach((element, index) => {
        element === monthZodiac ? branch.monthBranch.push(getYearBranchResult[index]) : null
    });

    // Year
    getYearBranchTable[currentYearZodiac].forEach((element, index) => {
        element === yearZodiac ? branch.yearBranch.push(getYearBranchResult[index]) : null
    });
    return branch
}
// Good
export const getMonthBranch = (natal) => {
    // Month’s Auxiliary 月神 in excel
    let currentYearZodiac = natal.currentYearNatal.animal.mandarin;

    let hourElement = natal.birthNatal.hour?.heavenly_stem.element.pinyin;
    let hourZodiac = natal.birthNatal.hour?.animal.mandarin;

    let dayElement = natal.birthNatal.day.heavenly_stem.element.pinyin;
    let dayZodiac = natal.birthNatal.day.animal.mandarin;

    let monthElement = natal.birthNatal.month.heavenly_stem.element.pinyin;
    let monthZodiac = natal.birthNatal.month.animal.mandarin;

    let yearElement = natal.birthNatal.year.heavenly_stem.element.pinyin;
    let yearZodiac = natal.birthNatal.year.animal.mandarin;

    let branch = {
        hourBranch: [],
        dayBranch: [],
        monthBranch: [],
        yearBranch: [],
    }

    if (natal.birthNatal.hour !== null) {
        getMonthBranchTable[currentYearZodiac].forEach((element, index) => {
            element === hourElement ? branch.hourBranch.push(getMonthBranchResult[index]) : null
            element === hourZodiac ? branch.hourBranch.push(getMonthBranchResult[index]) : null
        });
    }
    // Day
    getMonthBranchTable[currentYearZodiac].forEach((element, index) => {
        element === dayElement ? branch.dayBranch.push(getMonthBranchResult[index]) : null
        element === dayZodiac ? branch.dayBranch.push(getMonthBranchResult[index]) : null
    });
    // Month
    getMonthBranchTable[currentYearZodiac].forEach((element, index) => {
        element === monthElement ? branch.yearBranch.push(getMonthBranchResult[index]) : null
        element === monthZodiac ? branch.yearBranch.push(getMonthBranchResult[index]) : null
    });
    // Year
    getMonthBranchTable[currentYearZodiac].forEach((element, index) => {
        element === yearElement ? branch.yearBranch.push(getMonthBranchResult[index]) : null
        element === yearZodiac ? branch.yearBranch.push(getMonthBranchResult[index]) : null
    });
    return branch

}
export const getDayMonthAuxilaryStar = (natal) => {
    // Year Branch's Auxiliary Stars
    let currentYearZodiac = natal.currentYearNatal.animal.mandarin;

    let hourElement = natal.birthNatal.hour?.heavenly_stem.element.name_en;
    let hourZodiac = natal.birthNatal.hour?.animal.mandarin;

    let dayElement = natal.birthNatal.day.heavenly_stem.element.name_en;
    let dayZodiac = natal.birthNatal.day.animal.mandarin;

    let monthElement = natal.birthNatal.month.heavenly_stem.element.name_en;
    let monthZodiac = natal.birthNatal.month.animal.mandarin;

    let yearElement = natal.birthNatal.year.heavenly_stem.element.name_en;
    let yearZodiac = natal.birthNatal.year.animal.mandarin;

    let branch = {
        hourBranch: [],
        dayBranch: [],
        monthBranch: [],
        yearBranch: [],
    }

    // Check if Natal Chart have Hour (animal.mandarin = 0 mean there is no data )
    if (natal.birthNatal.hour !== null) {
        zodiacTable[currentYearZodiac].forEach((element, index) => {
            element === hourZodiac ? branch.hourBranch.push(zodiacResult[index]) : null;
        });
        zodiacTable2[currentYearZodiac].forEach((element, index) => {
            element === hourElement ? branch.hourBranch.push(zodiacResult[index]) : null;
            element === hourZodiac ? branch.hourBranch.push(zodiacResult[index]) : null;
        });
    }

    // Day
    zodiacTable[currentYearZodiac].forEach((element, index) => {
        element === dayZodiac ? branch.dayBranch.push(zodiacResult[index]) : null;
    });
    zodiacTable2[currentYearZodiac].forEach((element, index) => {
        element === dayElement ? branch.dayBranch.push(zodiacResult[index]) : null;
        element === dayZodiac ? branch.dayBranch.push(zodiacResult[index]) : null;
    });
    // Month
    zodiacTable[currentYearZodiac].forEach((element, index) => {
        element === monthZodiac ? branch.monthBranch.push(zodiacResult[index]) : null;
    });
    zodiacTable2[currentYearZodiac].forEach((element, index) => {
        element === monthElement ? branch.monthBranch.push(zodiacResult[index]) : null;
        element === monthZodiac ? branch.monthBranch.push(zodiacResult[index]) : null;
    });
    // Year
    zodiacTable[currentYearZodiac].forEach((element, index) => {
        element === yearZodiac ? branch.yearBranch.push(zodiacResult[index]) : null;
    });
    zodiacTable2[currentYearZodiac].forEach((element, index) => {
        element === yearElement ? branch.yearBranch.push(zodiacResult[index]) : null;
        element === yearZodiac ? branch.yearBranch.push(zodiacResult[index]) : null;
    });
    return branch
}

export const getDayStem = (natal) => {
    // Day Stem's in excel
    let currentYearElement = natal.currentYearNatal.heavenly_stem.element.full_name_en;

    let hourElement = natal.birthNatal.hour?.heavenly_stem.element.full_name_en;
    let hourZodiac = natal.birthNatal.hour?.animal.mandarin;

    let dayElement = natal.birthNatal.day.heavenly_stem.element.full_name_en;
    let dayZodiac = natal.birthNatal.day.animal.mandarin;

    let monthElement = natal.birthNatal.month.heavenly_stem.element.full_name_en;
    let monthZodiac = natal.birthNatal.month.animal.mandarin;

    let yearElement = natal.birthNatal.year.heavenly_stem.element.full_name_en;
    let yearZodiac = natal.birthNatal.year.animal.mandarin;

    let branch = {
        hourBranch: [],
        dayBranch: [],
        monthBranch: [],
        yearBranch: [],
    }

    // Check if Natal.birthNatal Chart have Hour (animal.mandarin = 0 mean there is no data )
    if (natal.birthNatal.hour !== null) {
        dayStemTable[currentYearElement].forEach((element, index) => {
            element === hourZodiac ? branch.hourBranch.push(dayStemTableResult[index]) : null
            element === hourElement ? branch.hourBranch.push(dayStemTableResult[index]) : null
            if (Array.isArray(element)) {
                element.includes(hourZodiac) ? branch.hourBranch.push(dayStemTableResult[index]) : null
            }
        });
    }

    // Day
    dayStemTable[currentYearElement].forEach((element, index) => {
        element === dayZodiac ? branch.dayBranch.push(dayStemTableResult[index]) : null
        element === dayElement ? branch.dayBranch.push(dayStemTableResult[index]) : null
        if (Array.isArray(element)) {
            element.includes(dayZodiac) ? branch.dayBranch.push(dayStemTableResult[index]) : null
        }
    });

    // Month
    dayStemTable[currentYearElement].forEach((element, index) => {
        element === monthZodiac ? branch.monthBranch.push(dayStemTableResult[index]) : null
        element === monthElement ? branch.monthBranch.push(dayStemTableResult[index]) : null
        if (Array.isArray(element)) {
            element.includes(monthZodiac) ? branch.monthBranch.push(dayStemTableResult[index]) : null
        }
    });

    // Year
    dayStemTable[currentYearElement].forEach((element, index) => {
        element === yearZodiac ? branch.yearBranch.push(dayStemTableResult[index]) : null
        element === yearElement ? branch.yearBranch.push(dayStemTableResult[index]) : null
        if (Array.isArray(element)) {
            element.includes(yearZodiac) ? branch.yearBranch.push(dayStemTableResult[index]) : null
        }
    });
    return branch

}

export const getDayBranch = (natal) => {
    // Day Branch's in excel
    let currentYearZodiac = natal.currentYearNatal.animal.mandarin;

    let hourZodiac = natal.birthNatal.hour?.animal.mandarin;
    let dayZodiac = natal.birthNatal.day.animal.mandarin;
    let monthZodiac = natal.birthNatal.month.animal.mandarin;
    let yearZodiac = natal.birthNatal.year.animal.mandarin;

    let branch = {
        hourBranch: [],
        dayBranch: [],
        monthBranch: [],
        yearBranch: [],
    }
    if (natal.birthNatal.hour !== null) {
        dayBranchTable[currentYearZodiac].forEach((element, index) => {
            element === hourZodiac ? branch.hourBranch.push(dayBranchResult[index]) : null
        });
    }
    // Day
    dayBranchTable[currentYearZodiac].forEach((element, index) => {
        element === dayZodiac ? branch.dayBranch.push(dayBranchResult[index]) : null
    });

    // Month
    dayBranchTable[currentYearZodiac].forEach((element, index) => {
        element === monthZodiac ? branch.monthBranch.push(dayBranchResult[index]) : null
    });

    // Year
    dayBranchTable[currentYearZodiac].forEach((element, index) => {
        element === yearZodiac ? branch.yearBranch.push(dayBranchResult[index]) : null
    });

    return branch
}

export const getYearBranchDestiny = (natal) => {
    // ดวงชะตาที่มีเคราห์ in excel
    let currentYearZodiac = natal.year.animal.mandarin;
    let monthZodiac = natal.month.animal.mandarin;

    let branch = {
        yearBranch: [],
    }

    getYearBranchTable[currentYearZodiac].forEach((element, index) => {
        element === monthZodiac ? branch.yearBranch.push(getYearBranchResult[index]) : null
    });

    return branch
}

export const getMonthBranchDestiny = (natal) => {
    // Month’s Auxiliary 月神 in excel
    let currentMonthZodiac = natal.month.animal.mandarin;

    let dayElement = natal.day.heavenly_stem.element.pinyin;
    let dayZodiac = natal.day.animal.mandarin;

    let branch = {
        monthBranch: [],
    }

    getMonthBranchTable[currentMonthZodiac].forEach((element, index) => {
        element === dayElement ? branch.monthBranch.push(getMonthBranchResult[index]) : null
        element === dayZodiac ? branch.monthBranch.push(getMonthBranchResult[index]) : null
    });

    return branch
}

export const getDayMonthAuxilaryStarDestiny = (natal) => {
    // Year Branch's Auxiliary Stars
    let currentYearZodiac = natal.year.animal.mandarin;

    let hourElement = natal.hour?.heavenly_stem.element.name_en;
    let hourZodiac = natal.hour?.animal.mandarin;

    let dayElement = natal.day.heavenly_stem.element.name_en;
    let dayZodiac = natal.day.animal.mandarin;

    let monthElement = natal.month.heavenly_stem.element.name_en;
    let monthZodiac = natal.month.animal.mandarin;

    let branch = {
        hourBranch: [],
        dayBranch: [],
        monthBranch: [],
    }

    if (natal.hour !== null) {
        zodiacTable[currentYearZodiac].forEach((element, index) => {
            element === hourZodiac ? branch.hourBranch.push(zodiacResult[index]) : null
        });
        zodiacTable2[currentYearZodiac].forEach((element, index) => {
            element === hourElement ? branch.hourBranch.push(zodiacResult2[index]) : null
            element === hourZodiac ? branch.hourBranch.push(zodiacResult2[index]) : null
        });
    }

    zodiacTable[currentYearZodiac].forEach((element, index) => {
        element === dayZodiac ? branch.dayBranch.push(zodiacResult[index]) : null
    });
    zodiacTable2[currentYearZodiac].forEach((element, index) => {
        element === dayElement ? branch.dayBranch.push(zodiacResult2[index]) : null
        element === dayZodiac ? branch.dayBranch.push(zodiacResult2[index]) : null
    });

    zodiacTable[currentYearZodiac].forEach((element, index) => {
        element === monthZodiac ? branch.monthBranch.push(zodiacResult[index]) : null
    });
    zodiacTable2[currentYearZodiac].forEach((element, index) => {
        element === monthElement ? branch.monthBranch.push(zodiacResult2[index]) : null
        element === monthZodiac ? branch.monthBranch.push(zodiacResult2[index]) : null
    });

    return branch
}

export const getDayStemDestiny = (natal) => {
    // Day Stem's in excel
    let currentDayElement = natal.day.heavenly_stem.element.full_name_en;

    let hourZodiac = natal.hour?.animal.mandarin;
    let hourElement = natal.hour?.heavenly_stem.element.full_name_en;

    let monthZodiac = natal.month.animal.mandarin;
    let monthElement = natal.month.heavenly_stem.element.full_name_en;

    let yearZodiac = natal.year.animal.mandarin;
    let yearElement = natal.year.heavenly_stem.element.full_name_en;

    let branch = {
        hourBranch: [],
        monthBranch: [],
        yearBranch: [],
    }

    if (natal.hour !== null) {
        dayStemTable[currentDayElement].forEach((element, index) => {
            element === hourZodiac ? branch.hourBranch.push(dayStemTableResult[index]) : null
            element === hourElement ? branch.hourBranch.push(dayStemTableResult[index]) : null
            if (Array.isArray(element)) {
                element.includes(hourZodiac) ? branch.hourBranch.push(dayStemTableResult[index]) : null
            }
        });
    }
    // Month
    dayStemTable[currentDayElement].forEach((element, index) => {
        element === monthZodiac ? branch.monthBranch.push(dayStemTableResult[index]) : null
        element === monthElement ? branch.monthBranch.push(dayStemTableResult[index]) : null
        if (Array.isArray(element)) {
            element.includes(monthZodiac) ? branch.monthBranch.push(dayStemTableResult[index]) : null
        }
    });
    // Year
    dayStemTable[currentDayElement].forEach((element, index) => {
        element === yearZodiac ? branch.yearBranch.push(dayStemTableResult[index]) : null
        element === yearElement ? branch.yearBranch.push(dayStemTableResult[index]) : null
        if (Array.isArray(element)) {
            element.includes(yearZodiac) ? branch.yearBranch.push(dayStemTableResult[index]) : null
        }
    });

    return branch
}
export const getDayBranchDestiny = (natal) => {
    // Day Branch's in excel
    let currentYearZodiac = natal.day.animal.mandarin;

    let hourZodiac = natal.hour?.animal.mandarin;
    let monthZodiac = natal.month.animal.mandarin;
    let yearZodiac = natal.year.animal.mandarin;

    let branch = {
        hourBranch: [],
        dayBranch: [],
        monthBranch: [],
        yearBranch: [],
    }
    if (natal.hour !== null) {
        dayBranchTable[currentYearZodiac].forEach((element, index) => {
            element === hourZodiac ? branch.hourBranch.push('addHourBranchDestiny', dayBranchResult[index]) : null
        });
    }
    dayBranchTable[currentYearZodiac].forEach((element, index) => {
        element === monthZodiac ? branch.monthBranch.push('addMonthBranchDestiny', dayBranchResult[index]) : null
    });

    dayBranchTable[currentYearZodiac].forEach((element, index) => {
        element === yearZodiac ? branch.yearBranch.push('addYearBranchDestiny', dayBranchResult[index]) : null
    });

    return branch
}