import axios from 'axios';
import store from '../store';
// .get('https://v2.api.numeiang.app/admin/v1/feedback/feedbacks')
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL + '/admin/v1/feedback',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use((config) => {
    if (store.getters.isAuthenticated) {
        // config.headers.common.Authorization = 'Bearer ' + store.getters.token;
        config.headers.setAuthorization('Bearer ' + store.getters.token)
    }
    return config;
});

export default instance;
