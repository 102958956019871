import Vue from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';

import vuetify from './plugins/vuetify';

import axios from 'axios';
import VueAxios from 'vue-axios';

import Vuelidate from 'vuelidate';

import './assets/styles/global.css';

Vue.config.productionTip = false;

axios.defaults.baseURL = 'https://api.numeiang.app';

new Vue({
	store,
	router,
	axios,
	vuetify,
	VueAxios,
	Vuelidate,
	render: (h) => h(App),
}).$mount('#app');
