import axios from "axios";
import store from "../store";

// default path date-selection
const instance = axios.create({
	baseURL: "https://api.numeiang.app",
	headers: {
		"Accept": "application/json",
		"Content-Type": "application/json",
	}
})

instance.interceptors.request.use((config) => {
	if (store.getters.isAuthenticated) {
		// config.headers.common.Authorization = 'Bearer ' + store.getters.token
		config.headers.setAuthorization('Bearer ' + store.getters.token)
	}
	return config;
});

export default instance;
