import axios from '@/api/axios-fortune-telling.js';
import axiosUser from '@/api/axios-users.js';
import godData from '@/constants/fortune-telling/10-gods.js';
import deepmerge from 'deepmerge';
import {
    getYearBranch,
    getMonthBranch,
    getDayMonthAuxilaryStar,
    getDayStem,
    getDayBranch,
    getYearBranchDestiny,
    getMonthBranchDestiny,
    getDayMonthAuxilaryStarDestiny,
    getDayStemDestiny,
    getDayBranchDestiny
} from "@/constants/fortune-telling/calculation.js"

const state = {
    auspiciousColor: {},
    luckPillar: [],
    monthlyInfluence: [],
    annualBazi: {
        hourBranch: [],
        dayBranch: [],
        monthBranch: [],
        yearBranch: [],
    },
    myDestiny: {
        hourBranch: [],
        dayBranch: [],
        monthBranch: [],
        yearBranch: [],
    },
    currentDate: null,
    tenGod: [],
    bazi: {},
    fiveStructure: [],
    userData: [],
    currentYear: {},
    searchHistory: [],
    // userDateOfBirth: null,
    // userTimeOfBirthh: null,
};


const getters = {
    bazi(state) {
        return state.bazi;
    },
    luckPillar(state) {
        return state.luckPillar;
    },
    fiveStructure(state) {
        return state.fiveStructure;
    },
    tenGod(state) {
        return state.tenGod;
    },
    annualBazi(state) {
        return state.annualBazi;
    },
    myDestiny(state) {
        return state.myDestiny;
    },
    monthlyInfluence(state) {
        return state.monthlyInfluence;
    },
    userData(state) {
        return state.userData;
    },
    searchHistory(state) {
        return state.searchHistory;
    },
    userDailyColor(state) {
        return state.auspiciousColor;
    },
    currentYear(state) {
        return state.currentYear;
    },
    currentDate(state) {
        return state.currentDate;
    },
};

const mutations = {
    addSearchDate(state, date) {
        state.searchHistory.push(date);
    },
    setMyDestiny(state, data) {
        state.myDestiny = data
    },
    setAnnualBazi(state, data) {
        state.annualBazi = data
    },
    clearAnnualBazi(state) {
        state.annualBazi.hourBranch = [];
        state.annualBazi.dayBranch = [];
        state.annualBazi.monthBranch = [];
        state.annualBazi.yearBranch = [];
    },

    clearMyDestiny(state) {
        state.myDestiny.hourBranch = [];
        state.myDestiny.dayBranch = [];
        state.myDestiny.monthBranch = [];
        state.myDestiny.yearBranch = [];
    },
    setUserAuspiciousColor(state, data) {
        state.auspiciousColor = data;
    },
    setUserDateOfBirth(state, data) {
        state.userDateOfBirth = data;
    },
    setUserTimeOfBirth(state, data) {
        state.userTimeOfBirthh = data;
    },
    setBazi(state, data) {
        state.bazi = data;
    },
    setLuckPillar(state, data) {
        state.luckPillar = data;
    },
    setMonthlyInfluence(state, data) {
        state.monthlyInfluence = data;
    },
    setFiveStructure(state, data) {
        state.fiveStructure = [...data];
    },
    setTenGod(state, data) {
        state.tenGod = data;
    },
    setUserData(state, data) {
        state.userData = data;
    },
    clearUserData(state) {
        state.userData = [];
    },
    setCurrentYear(state, currentYearNatal) {
        state.currentYear = currentYearNatal;
    },
    setCurrentDate(state, date) {
        state.currentDate = date;
    },
    deleteSearchDate(state, index) {
        state.searchHistory.splice(index, 1);
    },
};

const actions = {
    clearUserData: ({ commit }) => {
        commit('clearUserData');
    },
    addUserSearch: ({ commit }, payload) => {
        commit('addSearchDate', payload);
    },
    deleteSearchDate: ({ commit }, index) => {
        commit('deleteSearchDate', index);
    },
    uploadLottery: ({ commit }, payload) => {
        axios
            .post('/lottery', payload.formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                dispatch('setAlertMessage', {
                    message: 'อัพโหลดสำเร็จ',
                    type: 'success',
                });
            })
            .catch((err) => {
                this.$refs.fileInput.remove();
                dispatch('setAlertMessage', {
                    message: 'เกิดข้อผิดพลาด',
                    type: 'error',
                });
            });
    },

    fetchDate: ({ commit, dispatch }, request) => {
        request.time = request.time === '' ? 'null' : request.time;
        let currentYearNatal = {};
        let birthNatal = {};
        let promises = [];
        let date = request.date;
        let params = {
            date: date,
            // time: request.time,
        };
        if (request.time !== 'null') {
            params.time = request.time
        }
        promises.push(
            axios
                .get(`/bazi`, { params: params })
                .then((res) => {
                    let currentDate = `${date} ${request.time === 'null' ? '' : request.time}`;
                    commit('setCurrentDate', currentDate);
                    let natal = res.data;
                    natal = getRemapBazi(natal);
                    birthNatal = { ...natal };
                    commit('setBazi', natal);
                    dispatch('getTenGod', natal);
                    dispatch('getMyDestiny', natal);
                })
                .catch((err) => { })
        );
        promises.push(
            axios
                .get(`/bazi`, { params: params })
                .then((res) => {
                    currentYearNatal = getRemapBazi(res.data).year
                    commit('setCurrentYear', currentYearNatal);
                })
                .catch((err) => {
                    console.log(err);
                })
        );
        Promise.all(promises).then(() => {
            dispatch('getAnnualBazi', {
                birthNatal: birthNatal,
                currentYearNatal: currentYearNatal,
            });
        });
    },

    fetchUserByID: async ({ commit, dispatch }, id) => {
        await axiosUser
            .get(`/users/${id}`)
            .then((res) => {
                // Check if user is found
                let user = res.data;
                if (user.date_of_birth) {
                    dispatch('fetchDate', {
                        date: user.date_of_birth,
                        time: user.time_of_birth === '' ? 'null' : user.time_of_birth,
                    });
                    dispatch('fetchLuckPillar', {
                        date: user.date_of_birth,
                        gender: user.gender,
                    });
                    commit('addSearchDate', {
                        name: user.full_name,
                        date: user.date_of_birth,
                        time: user.time_of_birth,
                        gender: user.gender,
                    });
                    commit('setUserData', user);
                } else {
                    dispatch('setAlertMessage', {
                        message: 'ไม่พบ user',
                        type: 'error',
                    });
                }
            })
            .catch((err) => { });
    },

    fetchLuckPillar: async ({ commit }, request) => {
        await axios
            .get('https://api.numeiang.app/calendar/luck-pillar', {
                params: {
                    date_of_birth: request.date,
                    gender: request.gender,
                },
            })
            .then((res) => {
                let reArrange = {
                    1: res.data[0],
                    10: res.data[1],
                    20: res.data[2],
                    30: res.data[3],
                    40: res.data[4],
                    50: res.data[5],
                    60: res.data[6],
                    70: res.data[7],
                    80: res.data[8],
                };
                commit('setLuckPillar', reArrange);
            })
            .catch((err) => { });
    },

    fetchMonthlyInfluence: ({ commit }, year) => {
        axios
            .get(`https://v2.api.numeiang.app/v2/calendar/monthly-influence?year=${year}`)
            .then((res) => {
                // console.log(res.data)
                const dateFormatter = new Intl.DateTimeFormat('en-US', {
                    day: 'numeric',
                    month: 'short',
                });
                let monthArray = res.data.map((r) => {
                    return {
                        ...r.month,
                        date: dateFormatter.format(new Date(r.date)),
                    };
                });

                monthArray = monthArray.map((r) => {
                    return {
                        ...r,
                        god: r.heavenly_stem.ten_god,
                        animal: {
                            mandarin: r.earthly_branch.mandarin,
                            name: r.earthly_branch.name_en,
                            name_th: r.earthly_branch.name_th,
                            pinyin: r.earthly_branch.pinyin,
                        },
                    };
                });
                monthArray = [null, ...monthArray];
                commit('setMonthlyInfluence', monthArray);
            })
            .catch((err) => { });
        // Promise.all(promises).then(() => {
        // 	monthArray = monthArray.map((month) => {
        // 		return (month = month.month);
        // 	});
        // 	// commit("setMonthlyInfluence", monthArray);
        // });
    },

    getFiveStructure: ({ commit, dispatch }, request) => {
        request.time = request.time === '' ? 'null' : request.time;
        let date = request.date;
        let params = {
            date: date,
            // time: request.time,
        };
        if (request.time !== 'null') {
            params.time = request.time
        }
        axios
            .get(`/five-structure`, { params: params }).then(res => {
                let elements = res.data
                let { metal, water, wood, fire, earth } = elements
                commit('setFiveStructure', [metal, water, wood, fire, earth]);
            })
    },

    getAnnualBazi: ({ commit, dispatch }, natal) => {
        let getYearBranch1 = getYearBranch(natal);
        let getMonthBranch1 = getMonthBranch(natal);
        let getDayMonthAuxilaryStar1 = getDayMonthAuxilaryStar(natal);
        let getDayStem1 = getDayStem(natal);
        let getDayBranch1 = getDayBranch(natal);
        let merge = deepmerge.all([getYearBranch1, getMonthBranch1, getDayMonthAuxilaryStar1, getDayStem1, getDayBranch1])
        commit('setAnnualBazi', merge)
    },

    getMyDestiny: ({ commit, dispatch }, natal) => {
        let getYearBranchDestiny1 = getYearBranchDestiny(natal);
        let getMonthBranchDestiny1 = getMonthBranchDestiny(natal);
        let getDayMonthAuxilaryStarDestiny1 = getDayMonthAuxilaryStarDestiny(natal);
        let getDayStemDestiny1 = getDayStemDestiny(natal);
        let getDayBranchDestiny1 = getDayBranchDestiny(natal);
        let merge = deepmerge.all([getYearBranchDestiny1, getMonthBranchDestiny1, getDayMonthAuxilaryStarDestiny1, getDayStemDestiny1, getDayBranchDestiny1])
        commit('setMyDestiny', merge)
    },

    getTenGod: ({ commit }, natal) => {
        let godCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        // Count each god in natal chart
        if (natal.hour !== null) {
            godCount[natal.hour?.god.id]++;
        }
        godCount[natal.month.god.id]++;
        godCount[natal.year.god.id]++;
        if (natal.hour !== null) {
            natal.hour?.hidden_stems.forEach((hour) => {
                godCount[hour.ten_god.id]++;
            });
        }
        natal.day.hidden_stems.forEach((day) => {
            godCount[day.ten_god.id]++;
        });

        natal.month.hidden_stems.forEach((month) => {
            godCount[month.ten_god.id]++;
        });

        natal.year.hidden_stems.forEach((year) => {
            godCount[year.ten_god.id]++;
        });

        // remove first element (unused)
        godCount.shift();
        godCount = godCount.map((element, index) => {
            return {
                value: parseFloat((element / Math.max(...godCount)).toFixed(3)),
                data: godData[index],
            };
        });
        commit('setTenGod', godCount);
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};

function getRemapBazi(bazi) {
    for (const key in bazi) {
        if (typeof bazi[key] !== 'object') continue;
        if (bazi[key] == null) continue;
        bazi[key] = {
            ...bazi[key],
            god: bazi[key].heavenly_stem.ten_god,
            animal: {
                mandarin: bazi[key].earthly_branch.mandarin,
                name: bazi[key].earthly_branch.name_en,
                name_th: bazi[key].earthly_branch.name_th,
                pinyin: bazi[key].earthly_branch.pinyin,
            },
        };
    }
    return bazi;
}


