import axios from "axios";
import store from "../store";

// default path for calendar
const instance = axios.create({
	baseURL: "https://api.admin.numeiang.app",
	// baseURL: "https://api.admin.numeiang.app",
	// baseURL: "http://localhost:8082",
	headers: {
		"Accept": "application/json",
		"Content-Type": "application/json"
	}
})

instance.interceptors.request.use((config) => {
	if (store.getters.isAuthenticated) {
		// config.headers.common.Authorization = 'Bearer ' + store.getters.token
		config.headers.setAuthorization('Bearer ' + store.getters.token)
	}
	return config;
});

export default instance;
