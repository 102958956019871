export default {
  engagement: 'ฤกษ์รับหมั้น',
  betrothal: 'ฤกษ์แห่ขั้นหมาก',
  preparing_bed: 'ฤกษ์จัดเตียงนอน',
  sewing_gown: 'ฤกษ์ตัดชุดวิวา',
  hair_combing: 'ฤกษ์เสริมสวย',
  arrival: 'ฤกษ์มงคลสมรสเข้าบ้านเจ้าบ่าว',
  car_purchasing: 'ฤกษ์ออกรถ',
  cesarean: 'ฤกษ์วันคลอด',
  starting_construction: 'ฤกษ์ก่อสร้าง',
  setting_up_shrine: 'ฤกษ์ตั้งศาล',
  ground_breaking: 'ฤกษ์มงคลเสาเอกปลูกสร้าง',
  contract_signing: 'ฤกษ์เซ็นสัญญา',
  business_opening: 'ฤกษ์เปิดกิจการ',
  entering_house: 'ฤกษ์ทำพิธีเข้าบ้านใหม่',
  worshiping_lord: 'ฤกษ์ทำพิธีไหว้เจ้าที่',
  surgery: 'ฤกษ์เข้ารับการผ่าตัด',
  treatment: 'ฤกษ์เริ่มการรักษาโรค',
  cosmetic_surgery: 'ฤกษ์ศัลยกรรมเพื่อความงาม',
  exam: 'ฤกษ์สอบ',
  new_wallet: 'ฤกษ์กระเป๋าสตางค์',
};
