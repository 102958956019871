import axios from '@/api/axios-auth';
import router from '../../router/index';
import userRole from '@/assets/user-role.js';
import { allowRoutes } from '@/router'

const EXPIRE_TIME = 7200;

const state = {
  idToken: null,
  userRole: null,
  isInitialize: false,
};

const mutations = {
  // Authorize
  authUser(state, userData) {
    state.idToken = userData.token;
  },
  // Clear token
  clearAuthData(state) {
    state.idToken = null;
    state.userRole = null;
  },
  setUserRole(state, role) {
    state.userRole = role;
  },
  setInitializeUser(state, status) {
    state.isInitialize = status;
  },
};

const actions = {
  login: async ({ commit, dispatch }, authData) => {
    await axios
      .post('/admin/login', {
        email: authData.email,
        password: authData.password,
      })
      // Store Token on local storage for auto login
      .then((res) => {
        res.data.expiresIn = EXPIRE_TIME;
        commit('authUser', {
          token: res.data.token,
        });
        const now = new Date();
        const expirationDate = new Date(now.getTime() + res.data.expiresIn * 1000);
        localStorage.setItem('token', res.data.token);
        axios
          .get('/admin/admin-role', {
            headers: {
              Authorization: 'Bearer ' + res.data.token,
            },
          })
          .then((response) => {
            let data = response.data;
            let role = userRole[data.role];
            commit('setUserRole', role);
            commit('setInitializeUser', true);
            localStorage.setItem('userRole', role);
            let defaultRoute = allowRoutes[role].defaultRedirectPath
            router.replace(defaultRoute)
          });
        localStorage.setItem('expirationDate', expirationDate);
        dispatch('setLogoutTimer', res.data.expiresIn);
      })
      .catch((error) => {
        commit('setInitializeUser', true);
        dispatch('setAlertMessage', { message: 'Email หรือ รหัสผ่านไม่ถูกต้อง', type: 'error' });
      });
  },

  // re-login when token is not expired
  checkCredential: async ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token');
      if (!token) {
        // dispatch('setAlertMessage', { message: 'No Token', type: 'red darken-3' });
        resolve({ status: 'error' });
        return
      }
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      const now = new Date();
      if (now >= expirationDate) {
        // dispatch('setAlertMessage', { message: 'Session หมดอายุแล้ว กรุณา Login ใหม่', type: 'red darken-3' });
        resolve({ status: 'error' });
        return
      }
      resolve({ status: 'success' });
    });
  },
  
  initializeUserFromLocalStorage: async ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token');
      commit('authUser', {
        token: token,
      });
      const role = localStorage.getItem('userRole');
      commit('setUserRole', role);
      commit('setInitializeUser', true);
      resolve({ status: 'success' });
    });
  },



  //   tryAutoLogin: async ({ commit, dispatch }) => {
  //     return new Promise((resolve, reject) => {
  //       const token = localStorage.getItem('token');
  //       if (!token) {
  //         dispatch('logout');
  //         dispatch('setAlertMessage', { message: 'Logout', type: 'red darken-3' });
  //         commit('setInitializeUser', true);
  //         resolve();
  //         return;
  //       }
  //       console.log('🚀 ~ file: login.js:65 ~ tryAutoLogin: ~ token:', token);

  //       const expirationDate = new Date(localStorage.getItem('expirationDate'));
  //       const now = new Date();
  //       if (now >= expirationDate) {
  //         dispatch('logout');
  //         dispatch('setAlertMessage', { message: 'Session หมดอายุแล้ว กรุณา Login ใหม่', type: 'red darken-3' });
  //         commit('setInitializeUser', true);
  //         resolve();
  //         return;
  //       }
  //       commit('authUser', {
  //         token: token,
  //       });
  //       const role = localStorage.getItem('userRole');
  //       commit('setUserRole', role);
  //       commit('setInitializeUser', true);
  //       resolve();
  //       if (role === 'content-writer') {
  //         router.replace('/content');
  //       } else {
  //         router.replace('/fortune-telling');
  //       }
  //     });
  //   },
  // Log user out and Remove token in localStorage
  logout: async ({ commit }) => {
    await axios
      .delete('/admin/logout')
      .then((res) => console.log(res))
      .catch((err) => {
        console.log(err);
      });
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    commit('clearAuthData');
    commit('setInitializeUser', true);
    router.replace('/signin');
  },
  // set time that admin stay sign in
  setLogoutTimer: async ({ dispatch }, expirationTime) => {
    setTimeout(() => {
      dispatch('logout');
      dispatch('setAlertMessage', { message: 'Session หมดอายุแล้ว กรุณา Login ใหม่', type: 'red darken-3' });
    }, expirationTime * 1000);
  },
};

const getters = {
  // get if user is authorized
  isAuthenticated(state) {
    return state.idToken !== null;
  },
  token(state) {
    return state.idToken;
  },
  userRole(state) {
    return state.userRole;
  },
  isInitialize(state) {
    return state.isInitialize;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
