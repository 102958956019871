export function breakerResult(type){
    return `${type} Breaker`
}
export const robberyShaResult = 'Robbery Sha';
export const calamityShaResult = 'Calamity Sha';
export const annualShaResult = 'Annual Sha';
export const noWealthDayResult = 'No Wealth';
export const personalWealthResult = 'Personal Wealth Sky Horse';
export const personalBreakerResult = 'Personal Breaker';
export const combineAnimalResult = 'Combined Animal';
export const combineAnimalYearResult = 'Year Combined Animal';
export const combineAnimalMonthResult = 'Month Combined Animal';
export const solitaryStarGroomResult = 'Solitary Star';
export const solitaryStarBrideResult = 'Loneliness Star';
export const skyHappinessStarResult = 'Sky Happiness Star';
export const yearProsperityResult = 'Year Prosperity Star ';
export const assetAcquireResult = 'Asset Acquisition';
export const heavenlyWealthStarResult = 'Heavenly Wealth Star';
export const personalExtinctionStarResult = 'Personal Extinction Star';
export const illnessStarResult = 'Illness Star';
export const heavenlyDoctorStarResult = 'Heavenly Doctor Star';
export const startingDietResult = 'Starting Diet';
export const personalNobleManResult = 'Personal Nobleman';
export const heavenlyVirtueNobleResult = 'Heavenly Virtue Noble';
export const monthlyVirtueNobleResult = 'Monthly Virtue Noble';
export const threeHarmonyPartialResult = 'Three Harmony Partial';
export const threeHarmonyResult = 'Three Harmony';
export const monthCrashResult = 'Month Crash';
