import axios from '@/api/axios-date-selection2.js';
import axiosDateSelection from '@/api/axios-date-selection.js';
import router from '@/router';
import { CandidateRequestSchema, candidateSchema } from '@/constants//date-selection/candidate-schema.js';
import auspiciousSchema from '@/constants//date-selection/auspicious-schema.js';
import auspiciousTh from '@/constants/date-selection/auspicious-th';

const state = {
  requests2: [],
  request2: null,
  totalRequestPage: 1,
  currentRequestPage: 1,
  totalRequest: 0,
  requestPageSize: 25,
  isLoadingRequests: false,
  auspiciousDates: [],
  submitType: null,
  isSubmittingAus: false,
};

const getters = {
  requests2: (state) => {
    return state.requests2;
  },
  request2: (state) => {
    return state.request2;
  },
  currentRequestPage: (state) => {
    return state.currentRequestPage;
  },
  totalRequestPage: (state) => {
    return state.totalRequestPage;
  },
  auspiciousDates: (state) => {
    return state.auspiciousDates;
  },
  isLoadingRequests: (state) => {
    return state.isLoadingRequests;
  },
  submitType: (state) => {
    return state.submitType;
  },
  isSubmittingAus(state) {
    return state.isSubmittingAus;
  },
};

const mutations = {
  setRequests2(state, requests) {
    state.requests2 = requests;
  },
  setRequest2(state, request) {
    state.request2 = request;
  },
  setSubmitType(state, type) {
    state.submitType = type;
  },
  clearRequest2(state) {
    state.request2 = null;
  },
  setRequestPage(state, page) {
    state.currentRequestPage = page;
  },
  setTotalRequest(state, totalRequest) {
    state.totalRequest = totalRequest;
  },
  setTotalRequestPage(state, page) {
    state.totalRequestPage = page;
  },
  isLoadingRequests(state, status) {
    state.isLoadingRequests = status;
  },
  setAuspiciousDates(state, auspiciousDates) {
    state.auspiciousDates = auspiciousDates;
  },
  clearAuspiciousDates(state) {
    state.auspiciousDates = [];
  },
  isSubmittingAus(state, newState) {
    state.isSubmittingAus = newState;
  },
};

const actions = {
  getAllRequest2: async ({ commit, getters, dispatch }, { user_id, status, type, date_range, isSearching }) => {
    commit('isLoadingRequests', true);
    let params = {
      page: getters.currentRequestPage,
      limit: 10,
    };
    if (isSearching) {
      params['page'] = 1;
      commit('setRequestPage', 1);
    }
    if (!isEmpty(user_id)) params.user_id = user_id;
    if (!isEmpty(status)) params.status = status;
    if (!isEmpty(type)) params.type = type;
    // if (!isEmpty(date_range)) params.date_range = date_range;
    await axios
      .get('/auspicious-dates', {
        params: params,
      })
      .then((res) => {
        if (res.data.auspices !== null) commit('setRequests2', res.data.auspices);
        else commit('setRequests2', []);
        commit('setTotalRequestPage', res.data.meta.pagination.pages);
        commit('setTotalRequest', res.data.meta.pagination.total);
        commit('isLoadingRequests', false);
      });
  },
  getRequest2: async ({ commit, dispatch, getters }, requestId) => {
    commit('clearRequest2');
    commit('clearAuspiciousDates');
    await axios.get('/auspicious-dates/' + requestId).then((res) => {
      let auspicious = res.data;
      let { type, status, remark = null } = res.data;
      let { submitType } = getters;
      let { candidate_dates, selected_candidate_date } = auspicious[type];
      let auspicious_dates = [];
      // Find Auspicious Date is Single or Multiple
      if (typeof auspicious[type].auspicious_date !== 'undefined') {
        if (auspicious[type].auspicious_date?.engagement == null) {
          auspicious_dates = null
        } else {
          auspicious_dates = [auspicious[type].auspicious_date];
        }
      } else {
        auspicious_dates = auspicious[type].auspicious_dates;
      }

      if (submitType == 'candidate') {
        auspicious_dates = candidate_dates
      }
      // Get Auspicious Object
      auspicious_dates = formatAuspicious(auspicious_dates, submitType, type, status);

      let selected_auspicious = auspicious_dates;
      if (submitType !== null && selected_auspicious !== null && (Array.isArray(selected_auspicious) && selected_auspicious.length !== 0)) {
        selected_auspicious = selected_auspicious.map((v) => {
          let newObject = {};
          let array = [];
          for (const objectKey in v) {
            newObject = {
              ...v[objectKey],
              key: objectKey,
              name: auspiciousTh[objectKey],
              time: `${v[objectKey].start_time}~${v[objectKey].end_time}`,
            };
            array.push(newObject);
          }
          return array;
        });
      }

      if (typeof auspicious[type].candidate_dates !== 'undefined') {
        auspicious = { ...auspicious, candidate_dates, selected_candidate_date };
      }
      // Provisioning
      auspicious = {
        ...auspicious,
        auspicious_dates,
        selected_auspicious,
        requireBlessing: type === 'exam',
        exam_info: type === 'exam' ? getExamInfo(auspicious[type].detail.person) : null,
        facing_direction: auspicious[type].detail.facing_direction ? auspicious[type].detail.facing_direction : null
      };
      commit('setRequest2', auspicious);

      commit('setAuspiciousDates', auspicious_dates);
      commit('setRemark', remark == null ? '' : remark);
      commit('setAuspiciousType', type);
      commit('clearSelectedAuspicious');
      dispatch('getPersons2', { detail: auspicious[type].detail, type: auspicious.type });
      dispatch('initCurrentAuspicious');
    });
  },
  deleteRequest2: async ({ dispatch }, requestId) => {
    await axios
      .delete('/auspicious-dates/' + requestId)
      .then((res) => {
        dispatch('setAlertMessage', { message: 'ลบ Request สำเร็จ', type: 'success' });
      })
      .catch((err) => {
        dispatch('setAlertMessage', { message: 'เกิดข้อผิดพลาด', type: 'error' });
      });
  },

  getPersons2: async ({ dispatch }, { detail, type }) => {
    let persons = getPersons(detail, type);
    dispatch('getPersonBazi2', persons);
  },

  getPersonBazi2: async ({ commit, getters, dispatch }, personsData) => {
    let persons = personsData;
    let promises = [];
    let { auspiciousType, submitType } = getters;
    persons.forEach((e, index) => {
      let promise = new Promise((resolve, reject) => {
        if (e.dob !== null) {
          axiosDateSelection
            .get(`/calendar/bazi/${e.dob}/${e.tob}`)
            .then((res) => {
              resolve({
                ...e,
                index: index,
                isActive: initActiveFilter(auspiciousType, index),
                bazi: res.data,
              });
            })
            .catch((err) => {
              reject({ ...e });
            });
        } else {
          resolve({
            ...e,
            index: index,
            isActive: initActiveFilter(auspiciousType, index),
            bazi: null,
          });
        }
      });
      promises.push(promise);
    });
    await Promise.allSettled(promises).then((data) => {
      let result = data.filter((e) => e.status == 'fulfilled').map((e) => e.value);
      let personsWithFiveStructure = getPersonsFiveStructure(result);
      commit('setPersons', personsWithFiveStructure);
    });
    if (submitType !== null) {
      let today = new Date();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();
      if (getters.calendar.length == 0) {
        await dispatch('getCalendar')
      }
      dispatch('getAuspiciousCalendar', {
        year: year,
        month: month,
      });
    }
  },

  submitAuspicious: async ({ dispatch, getters, commit }) => {
    commit('isSubmittingAus', true);
    let { submitType } = getters;

    if (submitType == 'candidate') {
      dispatch('submitTypeCandidate');
    } else {
      dispatch('submitTypeAuspicious');
    }
  },

  submitTypeCandidate: async ({ dispatch, getters, commit }) => {
    let { request2, auspiciousType, submitType } = getters;
    let selectedList = getters.selectedAuspicious;
    selectedList = selectedList.map((ausArray) => {
      let temp = ausArray.map((a) => {
        let start_time = a.time.split('~')[0];
        let end_time = a.time.split('~')[1];
        let x = {
          [a.key]: {
            date: a.date,
            start_time: start_time,
            end_time: end_time,
          },
        };
        if (a.blessing) x.blessing = a.blessing;
        return { ...x };
      });
      let newObj = Object.fromEntries(
        Object.entries(temp).map(([key, val]) => {
          let keyz = Object.keys(val)[0];
          return [keyz, val[keyz]];
        })
      );
      return {
        ...newObj,
      };
    });
    let list = selectedList;
    let requestForm = CandidateRequestSchema;
    requestForm.marriage.candidate_dates = list;
    await axios
      .post(`/auspicious-dates/${request2.id}/candidate-dates`, requestForm)
      .then(() => {
        dispatch('setAlertMessage', { message: 'Candidate dates are submitted!', type: 'success' });
        commit('isSubmittingAus', false);

        router.go(-1);
      })
      .catch((err) => {
        dispatch('setAlertMessage', { message: 'เกิดข้อผิดพลาด!', type: 'error' });
        commit('isSubmittingAus', false);
      });
  },

  submitTypeAuspicious: async ({ dispatch, getters, commit }) => {
    let selectedAuspicious = getters.selectedAuspicious;
    let { request2, auspiciousType, remark } = getters;
    selectedAuspicious = selectedAuspicious.map((ausArray) => {
      let temp = ausArray.map((a) => {
        let start_time = a.time.split('~')[0];
        let end_time = a.time.split('~')[1];
        let x = {
          [a.key]: {
            date: a.date,
            start_time: start_time,
            end_time: end_time,
          },
        };
        if (a.blessing) x.blessing_id = a.blessing;
        if (a.blessing) x[a.key].blessing = a.blessing;
        return { ...x };
      });
      let newObj = Object.fromEntries(
        Object.entries(temp).map(([key, val]) => {
          let keyz = Object.keys(val)[0];
          let newVal = { ...val[keyz] };
          if (val.blessing_id) newVal.blessing_id = val.blessing_id;
          return [keyz, newVal];
        })
      );
      if (typeof newObj['exam']?.blessing !== 'undefined') {
        newObj.blessing_id = newObj['exam'].blessing;
      }
      return {
        ...newObj,
      };
    });
    let NOT_ALLOW_MULTIPLE = ['marriage'];
    let form;
    if (NOT_ALLOW_MULTIPLE.includes(auspiciousType)) {
      form = {
        remark: remark,
        [auspiciousType]: {
          auspicious_date: selectedAuspicious[0],
        },
      };
    } else {
      form = {
        remark: remark,
        [auspiciousType]: {
          auspicious_dates: selectedAuspicious,
        },
      };
    }
    await axios
      .post(`/auspicious-dates/${request2.id}/auspicious-dates`, form)
      .then((res) => {
        dispatch('setAlertMessage', { message: 'ส่งฤกษ์สำเร็จ', type: 'success' });
        commit('isSubmittingAus', false);
        dispatch('getRequest2', request2.id);
      })
      .catch((err) => {
        dispatch('setAlertMessage', { message: 'เกิดข้อผิดพลาด!', type: 'error' });
        commit('isSubmittingAus', false);
      });
  },

  setRequestPage: async ({ commit }, pageNumber) => {
    commit('setRequestPage', pageNumber);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

function getPersons(detail, type) {
  let persons = [];
  switch (type) {
    case 'marriage':
      {
        let groomName = `${detail['groom'].first_name} ${detail['groom'].last_name}`;
        let brideName = `${detail['bride'].first_name} ${detail['bride'].last_name}`;
        let groom = personFactory('เจ้าบ่าว', 'G', groomName, detail['groom'].date_of_birth, detail['groom'].time_of_birth);
        let bride = personFactory('เจ้าสาว', 'B', brideName, detail['bride'].date_of_birth, detail['bride'].time_of_birth);
        let groomFather = personFactory(
          'พ่อของเจ้าบ่าว',
          'P1(GF)',
          '',
          detail['groom_father'].date_of_birth,
          detail['groom_father'].time_of_birth || null
        );
        let groomMother = personFactory(
          'แม่ของเจ้าบ่าว',
          'P2(GM)',
          '',
          detail['groom_mother'].date_of_birth,
          detail['groom_mother'].time_of_birth || null
        );
        let brideFather = personFactory(
          'พ่อของเจ้าสาว',
          'P3(BF)',
          '',
          detail['bride_father'].date_of_birth,
          detail['bride_father'].time_of_birth || null
        );
        let brideMother = personFactory(
          'แม่ของเจ้าสาว',
          'P4(BM)',
          '',
          detail['bride_mother'].date_of_birth,
          detail['bride_mother'].time_of_birth || null
        );
        persons = [groom, bride, groomFather, groomMother, brideFather, brideMother];
      }
      break;
    case 'new_car':
      {
        persons = detail.members;
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`;
          return personFactory(`เจ้าของรถคนที่ ${index + 1}`, `M${index + 1}`, name, p.date_of_birth, p.time_of_birth);
        });
      }
      break;
    case 'birth':
      {
        let fatherName = `${detail['father'].first_name} ${detail['father'].last_name}`;
        let motherName = `${detail['mother'].first_name} ${detail['mother'].first_name}`;
        let father = personFactory('บิดา', 'FT', fatherName, detail['father'].date_of_birth, detail['father'].time_of_birth || null);
        let mother = personFactory('มารดา', 'MT', motherName, detail['mother'].date_of_birth, detail['mother'].time_of_birth || null);
        let child = personFactory('บุตร', 'B', '', null, null);
        child = {
          ...child,
          baby_gender: detail.baby_gender,
          baby_year_of_birth: detail.baby_year_of_birth,
        };
        persons = [father, mother, child];
      }
      break;
    case 'non_residential_construction':
      {
        persons = detail.members;
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`;
          return personFactory(`เจ้าของที่ดินคนที่ ${index + 1}: ${p.ownership_status}`, `M${index + 1}`, name, p.date_of_birth, p.time_of_birth);
        });
      }
      break;
    case 'residential_construction':
      {
        persons = detail.members;
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`;
          return personFactory(`เจ้าของบ้านคนที่ ${index + 1}: ${p.ownership_status}`, `M${index + 1}`, name, p.date_of_birth, p.time_of_birth);
        });
      }
      break;
    case 'house_moving':
      {
        persons = detail.members;
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`;
          return personFactory(`ผู้อาศัยคนที่ ${index + 1}: ${p.ownership_status}`, `M${index + 1}`, name, p.date_of_birth, p.time_of_birth);
        });
      }
      break;
    case 'contract_signing':
      {
        persons = detail.members;
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`;
          return personFactory(`ผู้ร่วมธุรกิจคนที่ ${index + 1}`, `M${index + 1}`, name, p.date_of_birth, p.time_of_birth);
        });
      }
      break;
    case 'business_opening':
      {
        persons = detail.members;
        persons = persons.map((p, index) => {
          let name = `${p['first_name']} ${p['last_name']}`;
          return personFactory(`ผู้เกี่ยวข้องที่ ${index + 1}: ${p.ownership_status}`, `M${index + 1}`, name, p.date_of_birth, p.time_of_birth);
        });
      }
      break;
    case 'surgery':
      {
        let person = detail.person;
        let name = `${person['first_name']} ${person['last_name']}`;
        let owner = personFactory('ผู้ร้องขอ', 'P', name, person['date_of_birth'], person['time_of_birth']);
        persons = [owner];
      }
      break;
    case 'treatment':
      {
        let person = detail.person;
        let name = `${person['first_name']} ${person['last_name']}`;
        let owner = personFactory('ผู้ร้องขอ', 'P', name, person['date_of_birth'], person['time_of_birth']);
        persons = [owner];
      }
      break;
    case 'cosmetic_surgery':
      {
        let person = detail.person;
        let name = `${person['first_name']} ${person['last_name']}`;
        let owner = personFactory('ผู้ร้องขอ', 'P', name, person['date_of_birth'], person['time_of_birth']);
        persons = [owner];
      }
      break;
    case 'new_wallet':
      {
        let person = detail.person;
        let name = `${person['first_name']} ${person['last_name']}`;
        let owner = personFactory('ผู้ร้องขอ', 'P', name, person['date_of_birth'], person['time_of_birth']);
        persons = [owner];
      }
      break;
    case 'exam':
      {
        let person = detail.person;
        let name = `${person['first_name']} ${person['last_name']}`;
        let owner = personFactory('ผู้ร้องขอ', 'P', name, person['date_of_birth'], person['time_of_birth']);
        persons = [owner];
      }
      break;
  }
  return persons;
}

function personFactory(title, acronym, name, dob, tob) {
  return {
    title: title,
    acronym: acronym,
    name: name,
    dob: !isEmpty(dob) ? dob : null,
    tob: !isEmpty(tob) ? tob : 'null',
  };
}
function getPersonsFiveStructure(persons) {
  let p = persons.map((p) => {
    return {
      ...p,
      fiveStructure: p.bazi ? getFiveStructure(p.bazi) : null,
    };
  });
  return p;
}

function initActiveFilter(type, index) {
  switch (type) {
    case 'marriage':
      if (index <= 1) return true;
      return false;
    case 'new_car':
      return true;
    case 'birth':
      return true;
    case 'non_residential_construction':
      if (index <= 0) return true;
      return true;
    case 'residential_construction':
      if (index <= 0) return true;
      return true;
    case 'house_moving':
      if (index <= 0) return true;
      return true;
    case 'contract_signing':
      if (index <= 0) return true;
      return true;
    case 'business_opening':
      if (index <= 0) return true;
      return true;
    case 'surgery':
      return true;
    case 'treatment':
      return true;
    case 'cosmetic_surgery':
      return true;
    default:
      return true;
  }
}

function getFiveStructure(natal) {
  let percentArray = [0, 0, 0, 0, 0];
  // Check if Natal Chart have Hour (animal.code = 0 mean there is no data )
  let haveHour = natal.hour.animal.code === 0 ? false : true;
  let notHiddenCellCount = 6;
  if (haveHour === true) {
    notHiddenCellCount = 8;
  }
  let addValue = 80 / notHiddenCellCount;
  if (haveHour === true) {
    percentArray[(natal.hour.heaven_element.code - 1) % 5] += addValue;
  }
  percentArray[(natal.day.heaven_element.code - 1) % 5] += addValue;
  percentArray[(natal.month.heaven_element.code - 1) % 5] += addValue;
  percentArray[(natal.year.heaven_element.code - 1) % 5] += addValue;

  if (haveHour === true) {
    percentArray[(natal.hour.earth_element.code - 1) % 5] += addValue;
  }
  percentArray[(natal.day.earth_element.code - 1) % 5] += addValue;
  percentArray[(natal.month.earth_element.code - 1) % 5] += addValue;
  percentArray[(natal.year.earth_element.code - 1) % 5] += addValue;

  let computedCap;
  let hiddenStemCount = notHiddenCellCount / 2;
  let hiddenStemValue = 20 / hiddenStemCount;
  if (haveHour === true) {
    computedCap = getCap(natal.hour.hidden_stem, hiddenStemValue);
    natal.hour.hidden_stem.forEach((hour) => {
      percentArray[(hour.element.code - 1) % 5] += computedCap;
    });
  }

  computedCap = getCap(natal.day.hidden_stem, hiddenStemValue);
  natal.day.hidden_stem.forEach((day) => {
    percentArray[(day.element.code - 1) % 5] += computedCap;
  });
  computedCap = getCap(natal.month.hidden_stem, hiddenStemValue);
  natal.month.hidden_stem.forEach((month) => {
    percentArray[(month.element.code - 1) % 5] += computedCap;
  });
  computedCap = getCap(natal.year.hidden_stem, hiddenStemValue);
  natal.year.hidden_stem.forEach((year) => {
    percentArray[(year.element.code - 1) % 5] += computedCap;
  });
  // wood fire metal water earth
  // metal water wood fire earth
  let reorder = [percentArray[2], percentArray[3], percentArray[0], percentArray[1], percentArray[4]];
  // reorder = percentArray
  return reorder;
}

const getCap = (hiddenStemArray, hiddenStemValue) => {
  return Number(hiddenStemValue / hiddenStemArray.length);
};

function isEmpty(obj) {
  return typeof obj == 'undefined' || obj == '' || obj == null || obj == '-';
}

function getExamInfo(person) {
  return {
    occupation: person.occupation,
    other_occupation: person.other_occupation,
    exam_type: person.exam_type,
  };
}

function formatAuspicious(auspicious_dates, submitType, type, status) {
  if (auspicious_dates == null) {
    let returnObj = submitType == 'candidate' ? [candidateSchema] : [auspiciousSchema[type]]
    return returnObj
  }

  return auspicious_dates.map((auspicious) => {
    let SPECIAL_TYPE = ['exam', 'new_wallet'];
    if (submitType == 'auspicious' && !['completed'].includes(status)) {
      return auspiciousSchema[type]
    }
    if (submitType == 'candidate' && ['date_selecting'].includes(status)) {
      return candidateSchema
    }
    // TYPE HAVE EXTRA DETAIL ATTACH
    if (SPECIAL_TYPE.includes(type)) {
      let obj = {
        [type]: {
          ...auspicious[type],
          blessing: type == 'exam' ? auspicious.blessing_id : null,
          lucky_colors: auspicious.lucky_colors ? auspicious.lucky_colors : null,
          wallet_info:
            type == 'new_wallet'
              ? {
                gift_bag_money_suggestion: auspicious.gift_bag_money_suggestion,
                wallet_shape_suggestion: auspicious.wallet_shape_suggestion,
                lucky_colors: auspicious.lucky_colors,
                star_of_prosperity: auspicious.star_of_prosperity,
              }
              : null,
        },
      };
      return obj;
    }
    return {
      ...auspicious,
    };
  });
}

function checkForEmptyProperties(obj) {
  for (const key in obj) {
    // skip info obj
    if (key == 'wallet_info') continue;
    if (typeof obj[key] === 'object') {
      if (checkForEmptyProperties(obj[key])) {
        return true; // Found an empty property in the nested object
      }
    } else {
      if (obj[key] === '') {
        return true; // Found an empty property
      }
    }
  }
  return false; // No empty properties found
}
